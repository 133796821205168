import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import 'moment/min/locales';
import {i18nMapStateToProps} from '../../../i18n';

import humidityIcon from '../../../assets/img/weather/icons/humidityIcon.png';
import pressureIcon from '../../../assets/img/weather/icons/pressureIcon.png';
import windIcon from '../../../assets/img/weather/icons/windIcon.png';
import windDirectionIcon from '../../../assets/img/weather/icons/windDirectionIcon.png';

import {BaseI18nComponentProps} from '../../../App';
import {WeatherIcon} from '../../../shared/server/weather';
import getIconAndClass from './weatherIcon';

interface InternalProps extends BaseI18nComponentProps {
  isForecast?: boolean;
  temperature?: number;
  temperatureDayMin?: number;
  temperatureDayMax?: number;
  temperatureNightMin?: number;
  temperatureNightMax?: number;
  cityName: string;
  weatherIcon: WeatherIcon;
  date: Date;
  humidity?: number;
  pressure?: number;
  windDirection: number;
  windSpeed: number;
  windSpeed2?: number;
  soilTemperature?: number;
}

class WeatherWidgetComponent extends React.PureComponent<InternalProps> {

  private getTempString(temp: number, temp2?: number) {
    const tempStr = (temp: number) => (temp < 0 ? '' : '+') + Math.round(temp) + '°';
    let result = tempStr(temp);
    if (temp2 || temp2 === 0) {
      result += ' ' + tempStr(temp2);
    }
    return (<span>{result}</span>);
  }

  render() {
    const t = this.props.i18n.t;
    const {bgClass, iconSrc} = getIconAndClass(this.props.weatherIcon);
    const tempInline = this.props.temperatureNightMin || this.props.temperatureNightMin === 0;
    return (
      <div className={'weather-main-widget-box ' + bgClass + (this.props.isForecast ? ' forecast' : '')}>
        <div className='up-row weather-bg-color'>
          <div>
            <img src={iconSrc} alt='weather icon' className='weather-icon' />
          </div>
          <div className='divider'></div>
          {this.props.temperature
            ? <div className='temperature-box'>
              <div className='temperature'>
                {this.getTempString(this.props.temperature)}
              </div>
              {this.props.soilTemperature &&
                <div className='temperature-soil-box'>
                  <div className='temperature-soil-label'>
                    {t['weather.soilTemperature.label']}
                  </div>
                  <div className='temperature-soil'>
                    {this.getTempString(this.props.soilTemperature)}
                  </div>
                </div>
              }
            </div>
            : (this.props.temperatureDayMin || this.props.temperatureDayMin === 0) &&
            <div className={'temperature-box ' + (tempInline ? 'flex-left' : '')}>
              <div className={'temperature ' + (tempInline ? 'temp-inline' : '')}>
                {tempInline && <span className='mr-1'>{t['weather.temperature.day']}</span>}
                {this.getTempString(this.props.temperatureDayMin, this.props.temperatureDayMax)}
              </div>
              {(this.props.temperatureNightMin || this.props.temperatureNightMin === 0) &&
                <div className='temperature temp-inline'>
                  {tempInline && <span className='mr-1'>{t['weather.temperature.night']}</span>}
                  {this.getTempString(this.props.temperatureNightMin, this.props.temperatureNightMax)}
                </div>
              }
            </div>
          }
          <div className='divider'></div>
          <div className='city-name-box'>
            {/* <div className='city-name'>{this.props.cityName}</div> */}
            <div className='date'>
              {this.props.isForecast
                ? moment(this.props.date).locale(this.props.i18n.lang).format('dddd, DD MMMM YYYY')
                : moment(this.props.date).locale(this.props.i18n.lang).format('dddd, DD MMMM YYYY HH:mm')
              }
            </div>
          </div>
        </div>
        <div className='values-row'>
          {this.props.humidity &&
            <div className='component-box weather-bg-color component-box-margin'>
              <div className='d-flex'>
                <div><img src={humidityIcon} alt='humidity' className='component-icon' /></div>
                <div className='component-label'>
                  {t['weather.humidity.label']}
                </div>
              </div>
              <div className='component-value d-flex justify-content-center'>
                {this.props.humidity}
                <div className='humidity-unit-text'>%</div>
              </div>
            </div>
          }
          {this.props.pressure &&
            <div className='weather-bg-color component-box component-box-margin'>
              <div className='d-flex'>
                <div><img src={pressureIcon} alt='humidity' className='component-icon' /></div>
                <div className='component-label'>
                  {t['weather_label_pressure']}
                </div>
              </div>
              <div className='component-value d-flex justify-content-center'>
                {this.props.pressure.toFixed(0)}
                <div className='pressure-unit-text'>
                  {t['weather_unit_pressure']}
                </div>
              </div>
            </div>
          }
          <div className={
            'weather-bg-color component-box component-box-wider ' +
            (!this.props.humidity && !this.props.pressure ? 'd-flex justify-content-between' : '')
          }>
            <div className='d-flex'>
              <div><img src={windIcon} alt='humidity' className='component-icon' /></div>
              <div className='component-label'>
                {t['weather_label_wind']}
              </div>
            </div>
            <div className='component-value d-flex justify-content-around text-nowrap'>
              {this.props.windSpeed}{this.props.windSpeed2 ? '-' + this.props.windSpeed2 : ''}
              {t['weather_unit_speed']}
              <div className='wind-direction ml-2'>
                <img src={windDirectionIcon} alt='direction'
                  className='wind-direction-icon'
                  style={{transform: `rotate(${this.props.windDirection}deg)`}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WeatherWidget = connect(i18nMapStateToProps)(WeatherWidgetComponent);

export default WeatherWidget;
