import React from 'react';
import {connect} from 'react-redux';
import {BaseAllComponentProps} from '../../App';
import {i18nMapStateToProps} from '../../i18n';
import {AppState, IUser} from '../../store';
import L from 'leaflet';
import {savedRun} from '../../shared/utils';
import config from '../../config';
import Server from '../../shared/server';
import {FormGroup, FormLabel} from 'react-bootstrap';
import Select from 'react-select';
import AppSelectStylesCalendar from '../calendar/appSelectStylesCalendar';
import moment from 'moment';

type TDimension = 'width' | 'height';

const mapOptions = {
  zoom: 5,
  minZoom: 5,
  center: {
    lat: 47.5,
    lng: 66
  },
  maxBounds: L.latLngBounds({lat: 40, lng: 46}, {lat: 56, lng: 88})
};

interface InternalProps extends BaseAllComponentProps {
  user?: IUser;
}

interface InternalState {
  isLoading: boolean;
  showFilter: boolean;
  filterDimension: TDimension;
  contracts: any[];
  contract?: any;
  dates: any[];
  date?: any;
  ndvi?: any;
}

class NdviComponent extends React.PureComponent<InternalProps, InternalState> {

  private _map?: L.Map;
  private _layer?: L.Layer;
  private updateInterval?: NodeJS.Timeout;

  constructor(props: InternalProps) {
    super(props);
    this.state = {
      isLoading: false,
      showFilter: true,
      filterDimension: 'width',
      contracts: [],
      dates: []
    };
  }

  private async updateLayer() {
    savedRun(async () => {
      if (this._layer) {
        this._map?.removeLayer(this._layer);
      }
      const token = await Server.getToken();
      this._layer = new L.TileLayer(config.host + config.mod + '/client/gis/{x}/{y}/{z}.png?token={accessToken}&lang={lang}&digest={digest}', {
        accessToken: token,
        digest: () => this.state.ndvi?.ndviDigest ?? '',
        tms: true,
        lang: this.props.i18n.lang
      } as any);
      this._layer.addTo(this._map as any);
      if (this.state.ndvi) {
        this._map?.setView(this.state.ndvi.center, this.state.ndvi.zoom);
      } else {
        this._map?.setView(mapOptions.center, mapOptions.zoom);
      }
    });
  }

  private async updateNdvi(contractId: string, date: string, ndvi?: any) {
    savedRun(async () => {
      try {
        this.setState({isLoading: true});
        const _ndvi = ndvi || await Server.ndvi.get(contractId, date);
        if (_ndvi) {
          await this.updateDigest(_ndvi.ndviDigest);
          if (this.updateInterval) {
            clearInterval(this.updateInterval);
          }
          this.updateInterval = setInterval(() => savedRun(async () => {
            await this.updateDigest(_ndvi.ndviDigest);
          }), 5000);
          this.setState({ndvi: _ndvi}, () => this.updateLayer());
        } else {
          this.setState({ndvi: _ndvi});
        }
      } finally {
        this.setState({isLoading: false});
      }
    });
  }

  componentDidMount() {
    savedRun(async () => {
      this.setState({isLoading: true});
      try {
        this._map = L.map('leaflet', mapOptions);
        this._map.zoomControl.setPosition('topright');
        const info = await Server.ndvi.getInfo();
        this.setState({contracts: info.contracts || []});
        if (!info.last) return;
        if (info.last) {
          const contract = info.contracts?.find((c: any) => c.contract._id === info.last?.contract._id);
          const dates = contract?.dates.map((d: string) => ({value: d, text: d}));
          const date = dates.find((d: any) => d.value === info.last?.date);
          this.setState({
            contract, dates, date
          });
          await this.updateNdvi(contract.contract._id, date, info.last);
        }
      } finally {
        this.setState({isLoading: false});
      }
    });
  }

  componentDidUpdate(prevProps: InternalProps) {
    if (prevProps.i18n.lang !== this.props.i18n.lang) {
      this.updateLayer();
    }
  }

  componentWillUnmount() {
    this._map?.off();
    this._map?.remove();
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  }

  private async updateDigest(digest?: string) {
    await savedRun(async () => {
      const _digest = digest || this.state.ndvi?.ndviDigest || '';
      if (digest !== '') {
        await Server.ndvi.updateDigest(_digest);
      }
    });
  }

  render() {
    const t = this.props.i18n.t;
    return (
      <div className='ndvi-box'>
        <div className={'filter-box' + (this.state.showFilter ? '' : ' hide')}
          onTransitionEnd={() => this._map?.invalidateSize()}
        >
          <div className='filter-content'>
            <FormGroup>
              <FormLabel>{t['fields.contract']}</FormLabel>
              <Select styles={AppSelectStylesCalendar} isSearchable={true}
                isLoading={this.state.isLoading}
                // placeholder={props.i18n.t['location']}
                options={this.state.contracts || []}
                getOptionLabel={(option: any) => option.contract.number}
                getOptionValue={(option: any) => option.contract._id}
                value={this.state.contract}
                onChange={(option) => {
                  const dates = option?.dates.map((d: string) => ({value: d, text: d}));
                  const date = dates[dates.length - 1];
                  this.setState({contract: option, dates, date});
                  this.updateNdvi(option.contract._id, date.value);
                }} />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t['fields.date']}</FormLabel>
              <Select styles={AppSelectStylesCalendar} isSearchable={true}
                isLoading={this.state.isLoading}
                // placeholder={props.i18n.t['location']}
                options={this.state.dates}
                getOptionLabel={(option: any) => moment(option.text).format('YYYY.MM.DD')}
                getOptionValue={(option: any) => option.value}
                value={this.state.date}
                onChange={(option: any) => {
                  this.setState({date: option});
                  this.updateNdvi(this.state.contract.contract._id, option.value);
                }} />
            </FormGroup>
          </div>
        </div>
        <div id='leaflet' className='map'></div>
        <div className={'filter-button' + (this.state.showFilter ? ' active' : '')}
          onClick={() => this.setState({showFilter: !this.state.showFilter})}
        >
          {t['fields.filter']}
        </div>
        <div className={'header-text' + (this.state.showFilter ? '' : ' center')}>
          {this.state.ndvi?.['title' + t['langUp']]}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  ...i18nMapStateToProps(state),
  user: state.identity.user
});

const Ndvi = connect(mapStateToProps)(NdviComponent);
export default Ndvi;
