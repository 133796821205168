import clearIcon from '../../../assets/img/weather/ClearIcon.png';
import cloudyIcon from '../../../assets/img/weather/CloudyIcon.png';
import clearCloudyIcon from '../../../assets/img/weather/ClearCloudyIcon.png';
import clearCloudyPrecipitationIcon from '../../../assets/img/weather/ClearCloudyPrecipitationIcon.png';
import clearCloudyRainIcon from '../../../assets/img/weather/ClearCloudyRainIcon.png';
import clearCloudySnowIcon from '../../../assets/img/weather/ClearCloudySnowIcon.png';
import clearCloudyStormIcon from '../../../assets/img/weather/ClearCloudyStormIcon.png';
import cloudyRainIcon from '../../../assets/img/weather/CloudyRainIcon.png';
import cloudyRainStormIcon from '../../../assets/img/weather/CloudyRainStormIcon.png';
import cloudySnowIcon from '../../../assets/img/weather/CloudySnowIcon.png';
import clearNightIcon from '../../../assets/img/weather/ClearNightIcon.png';
import clearCloudyNightIcon from '../../../assets/img/weather/ClearCloudyNightIcon.png';
import clearCloudyRainNightIcon from '../../../assets/img/weather/ClearCloudyRainNightIcon.png';
import {WeatherIcon} from '../../../shared/server/weather';

const getIconAndClass = (weatherIcon: WeatherIcon) => {
  switch (weatherIcon) {
    case WeatherIcon.Clear: return {bgClass: 'weather-clear', iconSrc: clearIcon};
    case WeatherIcon.Cloudy: return {bgClass: 'weather-cloudy', iconSrc: cloudyIcon};
    case WeatherIcon.ClearCloudy: return {bgClass: 'weather-clear-cloudy', iconSrc: clearCloudyIcon};
    case WeatherIcon.ClearCloudyPrecipitation: return {bgClass: 'weather-clear-cloudy-precipitation', iconSrc: clearCloudyPrecipitationIcon};
    case WeatherIcon.ClearCloudyRain: return {bgClass: 'weather-clear-cloudy-rain', iconSrc: clearCloudyRainIcon};
    case WeatherIcon.ClearCloudySnow: return {bgClass: 'weather-clear-cloudy-snow', iconSrc: clearCloudySnowIcon};
    case WeatherIcon.ClearCloudyStorm: return {bgClass: 'weather-clear-cloudy-storm', iconSrc: clearCloudyStormIcon};
    case WeatherIcon.CloudyRain: return {bgClass: 'weather-cloudy-rain', iconSrc: cloudyRainIcon};
    case WeatherIcon.CloudyRainStorm: return {bgClass: 'weather-cloudy-rain-storm', iconSrc: cloudyRainStormIcon};
    case WeatherIcon.CloudySnow: return {bgClass: 'weather-cloudy-snow', iconSrc: cloudySnowIcon};
    case WeatherIcon.ClearNight: return {bgClass: 'weather-clear-night', iconSrc: clearNightIcon};
    case WeatherIcon.ClearCloudyNight: return {bgClass: 'weather-clear-cloudy-night', iconSrc: clearCloudyNightIcon};
    case WeatherIcon.ClearCloudyRainNight: return {bgClass: 'weather-clear-cloudy-rain-night', iconSrc: clearCloudyRainNightIcon};
    default: return {bgClass: 'weather-clear', iconSrc: clearIcon};
  }
}

export default getIconAndClass;
