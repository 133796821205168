import React from 'react';
import {connect} from 'react-redux';
import {BaseI18nComponentProps, Routes} from '../../../App';
import {i18nMapStateToProps} from '../../../i18n';
import {Container} from 'react-bootstrap';

class FooterComponent extends React.PureComponent<BaseI18nComponentProps> {
  render() {
    const t = this.props.i18n.t;
    return (
      <div className='fixed-bottom footer-box'>
        <Container fluid='lg' className='footer-inner-box'>
          <div>
            {t['footer_main_text']}
          </div>
          <div>
            <a href={'#' + Routes.Instruction}>{t['nav.instruction']}</a>
          </div>
        </Container>
      </div>
    );
  }
}

const Footer = connect(i18nMapStateToProps)(FooterComponent);
export default Footer;
