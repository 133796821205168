import React, {ReactNode} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {AppState, IUser} from '../../store';
import {savedRun} from '../../shared/utils';
import {BaseI18nComponentProps} from '../../App';
import 'react-pdf/dist/esm/entry.webpack';
import {Document, Page} from 'react-pdf';
import Server from '../../shared/server';
import {TClientContractStatus} from '../../shared/server/contract';
import NCALayer from '../../shared/ncalayer';
import CoverSpinner from '../../shared/components/plain/spinner/spinner';
import {CheckAll} from 'react-bootstrap-icons';
import Feedback from 'react-bootstrap/Feedback';

interface InternalProps extends BaseI18nComponentProps {
  user?: IUser;

  show: boolean;
  onHide: (needUpdate: boolean) => void;
  onEnter: () => void;
  contract: {status: TClientContractStatus} & any;
}
interface InternalState {
  isSigning: boolean;
  isLoading: boolean;

  validation?: any;

  pdfFile?: Blob;

  pageCount: number;
  pageWidth: number;
}

class ContractPdfComponent extends React.PureComponent<InternalProps, InternalState> {

  constructor(props: InternalProps) {
    super(props);
    this.state = {
      isLoading: false,
      isSigning: false,

      pageCount: 0,
      pageWidth: 0
    };
  }

  private documentRef?: HTMLDivElement;
  private documentWidth = 0;

  private resizeHandler = () => {
    if (this.documentRef && this.documentWidth !== this.documentRef.offsetWidth) {
      this.documentWidth = this.documentRef.offsetWidth;
      this.setState({pageWidth: this.documentWidth});
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  private onEnter() {
    this.props.onEnter();
    savedRun(async () => {
      try {
        this.setState({isLoading: true});
        const pdfBlob = await Server.Files.getBlob(this.props.contract.fileId);
        if (pdfBlob) {
          this.setState({pdfFile: pdfBlob});
        }
      } finally {
        this.setState({isLoading: false});
      }
    });
  }

  private onHide(needUpdate = false) {
    this.setState({
      pdfFile: undefined,
      pageCount: 0
    });
    this.props.onHide(needUpdate);
  }

  private sign() {
    savedRun(async () => {
      if (!this.state.pdfFile) {
        return;
      }
      this.setState({isSigning: true, validation: undefined});
      try {
        let res = new Response(this.state.pdfFile);
        const arrayBuffer = await res.arrayBuffer();
        const buffer = Buffer.from(arrayBuffer);
        const base64 = buffer.toString('base64');
        const nca = new NCALayer();
        await nca.open();
        const activeTokens = await nca.getActiveTokens();
        console.log(`Active tokens from nca: ${activeTokens}`);
        const signed = await nca.createCMSSignatureFromBase64('PKCS12', 'SIGNATURE', base64);
        this.setState({isSigning: false, isLoading: true});
        const signedBuffer = Buffer.from(signed, 'base64');
        res = new Response(signedBuffer, {headers: {'content-type': 'application/pdf'}});
        const signedBlob = await res.blob();
        const result = await Server.Contract.sendToCheck(signedBlob, 'Contract-' + this.props.contract.number, this.props.contract._id);
        if (result) {
          this.onHide(true);
        }
        this.setState({isLoading: false});
      } catch (err) {
        this.setState({isSigning: false, isLoading: false});
        if (err.wasClean === false) {
          window.alert(this.props.i18n.t['nca.error']);
        }
        if (err.validation?.success === false) {
          this.setState({validation: {...this.state.validation, ...err.validation}});
        }
      }
    });
  }

  private renderPages() {
    const pages: ReactNode[] = [];
    for (let i = 1; i <= this.state.pageCount; i++) {
      pages.push(<Page key={i} className='pdf-page' pageNumber={i} width={this.state.pageWidth} />);
    }
    return pages;
  }

  render() {
    const t = this.props.i18n.t;
    return <Modal size='xl' className='contract-pdf-box'
      show={this.props.show}
      onHide={() => this.onHide()}
      onEnter={() => this.onEnter()}
    >
      {(this.state.isLoading || this.state.isSigning) &&
        <div className='contract-spinner'>
          <CoverSpinner show={this.state.isLoading || this.state.isSigning}
            text={this.state.isSigning ? t['nca.message'] : undefined}
          />
        </div>
      }
      <Modal.Header closeButton>
        <Modal.Title>{t['actions.contract.view']}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.pdfFile &&
          <>
            <Document className='pdf-document'
              inputRef={(doc) => {
                this.documentRef = doc || undefined;
                this.resizeHandler();
              }}
              file={this.state.pdfFile}
              onLoadSuccess={(pdfInfo) => {
                this.setState({pageCount: pdfInfo.numPages});
              }}
            >
              {this.renderPages()}
            </Document>
            {this.props.contract.signedFileId &&
              <>
                <div className='signed'>
                  <CheckAll color='green' />
                  {t['contract.status.signed.client']}
                </div>
                {(this.props.contract.status as TClientContractStatus) === 'active' &&
                  <div className='signed'>
                    <CheckAll color='green' />
                    {t['contract.status.signed.kgm']}
                  </div>
                }
              </>
            }
          </>
        }
        <Feedback type='invalid' style={{display: this.state.validation?.signed === 'invalid' ? 'block' : 'none'}}>
          {t['errors.sign.invalid']}
        </Feedback>
      </Modal.Body>
      <Modal.Footer>
        {(['new', 'returned'] as TClientContractStatus[]).some(t => t === this.props.contract.status) &&
          <Button variant='primary'
            onClick={() => this.sign()}
            disabled={!this.state.pdfFile}
          >
            {t['actions.signsend']}
          </Button>
        }
        <Button variant='light' onClick={() => this.onHide()}>
          {t['actions.cancel']}
        </Button>
      </Modal.Footer>
    </Modal >;
  }
}

const mapStateToProps = (state: AppState) => ({
  i18n: state.i18n,
  user: state.identity.user
});

const ContractPdf = connect(mapStateToProps)(ContractPdfComponent);
export default ContractPdf;
