import Weather from './weather';
import Calendar from './calendar';
import ForecastsMoisture from './forecastMoisture';
import ForecastsDrought from './forecastDrought';
import ForecastsProductivity from './forecastProductivity';
import Pages from './pages';
import ActualMoisture from './actualMoisture';
import ActualNdvi from './actualNdvi';
import jwtDecode from 'jwt-decode';
import Validation, {ValidationError} from './validation';
import config from '../../config';
import Client from './client';
import store, {IdentityActions, IUser} from '../../store';
import Contract from './contract';
import Files from './files';
import Ndvi from './ndvi';
import BalanceChanges from './balanceChanges';

interface IResponseObj {
  success: boolean;
  result?: any;
  error?: any;
}

const setTokens = (accessToken?: string, updateToken?: string) => {
  if (accessToken) {
    localStorage.setItem('access_token', accessToken);
  }
  if (updateToken) {
    localStorage.setItem('update_token', updateToken);
  }
};

const removeTokens = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('update_token');
};

const getAccessToken = () => localStorage.getItem('access_token');
const getUpdateToken = () => localStorage.getItem('update_token');

export const defaultHeaders = {
  'Content-Type': 'application/json'
};

export const addQueryParams = (obj: {[key: string]: any}): string => {
  const str = Object
    .keys(obj)
    .filter(key => !!obj[key])
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
  return `?${str}`;
}

export const processResponse = async (res: Response) => {
  if (res.status) {
    const obj: IResponseObj = await res.json();
    if (res.status < 400 && obj.success) {
      return obj.result;
    } else if (res.status === 401) {
      removeTokens();
    } else if (res.status === 409 || res.status === 422) {
      throw new ValidationError('Server validation failed', obj.error?.validation);
    }
    console.error(obj.error);
    return null;
  }
  console.error('Server unavailable');
  return null;
}

export const login = async (login: string, password: string) => {
  const response = await fetch(config.host + config.mod + config.identity + '/client/token', {
    headers: {
      ...defaultHeaders,
      Authorization: 'Basic ' + btoa(`${login}:${password}`)
    }
  });
  const result = await processResponse(response);
  if (result) {
    setTokens(result.accessToken, result.updateToken);
    const user = await getUser();
    IdentityActions.updateUser(store.dispatch)(user);
    return true;
  }
  return false;
}

export const logout = () => {
  removeTokens();
}

export const getToken = async () => {
  const token = getAccessToken();
  if (token) {
    const decoded = jwtDecode<any>(token);
    const now = (new Date().getTime() / 1000) + 60;
    if (decoded.exp > now) {
      return token;
    }
  }
  const update_token = getUpdateToken();
  if (update_token) {
    const decoded = jwtDecode<any>(update_token);
    const now = (new Date().getTime() / 1000) + 60;
    if (decoded.exp <= now) {
      logout();
      return;
    }
    const params = addQueryParams({rememberMe: true});
    const response = await fetch(config.host + config.mod + config.identity + '/client/token' + params, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: 'Bearer ' + update_token,
      }
    });
    const result = await processResponse(response);
    if (result) {
      setTokens(result.accessToken, result.updateToken);
      return result.accessToken;
    }
  }
}

export const headersAuthOnly = async () => {
  const token = await getToken();
  if (!token) {
    removeTokens();
    // window.location.reload();
  }
  return {Authorization: 'Bearer ' + token};
}

export const headersWithAuth = async () => {
  return {...defaultHeaders, ...await headersAuthOnly()};
};

const hasAuth = () => !!getAccessToken();

const getUser = async () => {
  const token = await getToken();
  if (!token) {
    return;
  }
  const decoded = jwtDecode<any>(token);
  return {id: decoded._id, bin: decoded.bin} as IUser;
}

const changePassword = async (_id: string, password: string, confirm: string) => {
  const response = await fetch(config.host + config.mod + config.identity + '/client/password', {
    method: 'PUT',
    headers: await headersWithAuth(),
    body: JSON.stringify({_id, password, confirm})
  });
  const result = await processResponse(response);
  return result;
}

class Server {
  public static getUser = getUser;
  public static hasAuth = hasAuth;
  public static login = login;
  public static logout = logout;
  public static changePassword = changePassword;
  public static getToken = getToken;
  public static Validation = Validation;
  public static Weather = Weather;
  public static Calendar = Calendar;
  public static Pages = Pages;
  public static ActualMoisture = ActualMoisture;
  public static ActualNdvi = ActualNdvi;
  public static Client = Client;
  public static Contract = Contract;
  public static balanceChanges = BalanceChanges;
  public static Files = Files;
  public static ndvi = Ndvi;
  public static Forecasts = {
    Moisture: ForecastsMoisture,
    Drought: ForecastsDrought,
    Productivity: ForecastsProductivity
  }
}

export default Server;
