import React from 'react';
import {Spinner} from 'react-bootstrap';
import {BaseI18nComponentProps} from '../../../App';
import {savedRun} from '../../utils';
import {connect} from 'react-redux';
import {i18nMapStateToProps} from '../../../i18n';
import Server from '../../server';

interface InternalProps extends BaseI18nComponentProps {
  pageName?: string;
  content?: string;
  isLoading?: boolean;
  customStyle?: boolean;
}
interface InternalState {
  isLoading: boolean;
  content: string;
}

class CKEditorPageComponent extends React.PureComponent<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      isLoading: false,
      content: ''
    };
  }

  componentDidMount() {
    this.updateContent();
  }

  componentDidUpdate(prevProps: InternalProps) {
    if (prevProps.i18n.lang !== this.props.i18n.lang) {
      this.updateContent();
    }
  }

  updateContent() {
    if (!this.props.pageName) {
      return;
    }
    this.setState({isLoading: true});
    savedRun(async () => {
      try {
        const content = await Server.Pages.get(this.props.pageName || '');
        this.setState({content, isLoading: false});
      } catch (e) {
        this.setState({isLoading: false});
        throw e;
      }
    });
  }

  render() {
    return (
      <div>
        {(this.state.isLoading || this.props.isLoading) &&
          <div className='loading-box'>
            <Spinner animation='grow' variant='primary' />
          </div>
        }
        <div className={this.props.customStyle ? 'ck-custom' : ''}>
          <div className='ck-content'
            dangerouslySetInnerHTML={{__html: (this.props.pageName ? this.state.content : this.props.content) || ''}}
          />
        </div>
      </div>
    );
  }
}

const CKEditorPage = connect(i18nMapStateToProps)(CKEditorPageComponent);

export default CKEditorPage;
