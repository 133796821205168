import React from 'react';
import {Button, Col, Container, Dropdown, FormControl, FormGroup, InputGroup, Pagination, Row, Spinner, Table} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import {connect} from 'react-redux';
import {BaseAllComponentProps} from '../../App';
import Server from '../../shared/server';
import {savedRun} from '../../shared/utils';
import {AppState, IUser} from '../../store';
import Contract from './contract';
import moment from 'moment';
import ContractPdf from './contractPdf';
import {TClientContractStatus} from '../../shared/server/contract';

interface InternalProps extends BaseAllComponentProps {
  user?: IUser;
}
interface InternalState {
  removingContractId?: string;
  isDownloading: boolean;

  showModal: boolean;
  servicesRef: any[];
  services: any[];

  contract?: any;
  contractPdf?: any;

  count: number;
  page: number;
  perPage: number;
  filter?: string;
  contracts: any[];

  pages: number[];
  pagesCount: number;
}

class ContractsComponent extends React.PureComponent<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      isDownloading: false,
      showModal: false,
      servicesRef: [],
      services: [],
      count: 0,
      page: 1,
      perPage: 10,
      contracts: [],
      pages: [1],
      pagesCount: 1
    };
  }

  private updateTimeout?: NodeJS.Timeout;
  private setUpdateTimeout() {
    this.removeUpdateTimeout();
    this.updateTimeout = setTimeout(() => {
      this.updateContracts();
    }, 5000);
  }
  private removeUpdateTimeout() {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }
  }

  private updateContracts() {
    savedRun(async () => {
      this.removeUpdateTimeout();
      const result = await Server.Contract.get(this.state.page, this.state.perPage, this.state.filter);
      const pagesCount = Math.ceil(result.count / this.state.perPage);
      const pages: number[] = [];
      const hasDraft = result.entities.some((e: any) => (['draft', 'generate'] as TClientContractStatus[]).some(s => s === e.status));
      if (hasDraft) {
        this.setUpdateTimeout();
      } else {
        this.removeUpdateTimeout();
      }
      for (let i = 0; i < pagesCount; i++) pages.push(i + 1);
      this.setState({count: result.count, contracts: result.entities, pagesCount, pages});
    });
  }

  componentDidMount() {
    this.updateContracts();
  }

  private remove(contract: any, index: number) {
    const message = this.props.i18n.t['actions.contract.remove'].replace('{contractNumber}', contract.number);
    if (window.confirm(message)) {
      savedRun(async () => {
        this.setState({removingContractId: contract._id});
        try {
          await Server.Contract.remove(contract._id);
          this.setState({removingContractId: undefined});
          this.updateContracts();
        } catch (err) {
          this.setState({removingContractId: undefined});
          throw err;
        }
      });
    }
  }

  private download(contract: any) {
    savedRun(async () => {
      const fileId = contract.signedFileId || contract.fileId;
      if (!fileId) return;
      this.setState({isDownloading: true});
      try {
        const token = await Server.Contract.getFileToken(contract._id, fileId);
        Server.Files.download(token, 'Contract-' + contract.number + '.pdf');
      } finally {
        this.setState({isDownloading: false});
      }
    });
  }

  private downloadFile(filename: string, id: string) {
    savedRun(async () => {
      const token = await Server.Files.getFileToken(id);
      Server.Files.download(token, filename);
    });
  }

  render() {
    const t = this.props.i18n.t;
    if (!this.props.user) {
      return null;
    }
    return (
      <Container fluid='xl' className='contracts-box'>
        {/* {this.state.contracts.length === 0
            ? <FormGroup>
              <div className='no-contracts-text'>{t['text.noContracts']}</div>
            </FormGroup>
            : <> */}
        <Row>
          <Col md='6'>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text><Icon.Search /></InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder={t['fields.contract.filter']}
                value={this.state.filter || ''}
                onChange={(event) => this.setState({filter: event.target.value}, () => this.updateContracts())}
              />
            </InputGroup>
          </Col>
          <Col md='6'>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>{t['fields.perPage']}</InputGroup.Text>
              </InputGroup.Prepend>
              <select className='form-control'
                value={this.state.perPage}
                onChange={(event) => this.setState({perPage: +event.target.value}, () => this.updateContracts())}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
              </select>
            </InputGroup>
          </Col>
        </Row>
        <Table striped hover>
          <thead>
            <tr>
              <th>№</th>
              <th>{t['fields.contract.number']}</th>
              <th>{t['fields.contract.date']}</th>
              <th>{t['fields.contract.state']}</th>
              <th>{t['fields.actions']}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.contracts.map((contract, index) => <tr key={contract._id}>
              <td>{(this.state.page - 1) * this.state.perPage + index + 1}</td>
              <td>{contract.number}</td>
              <td>{contract.date ? moment(contract.date).format('YYYY.MM.DD HH:mm') : ''}</td>
              <td>{t['fields.contract.state.' + contract.status]}</td>
              <td className='p-1'>
                {(['new', 'returned', 'active', 'done', 'check'] as TClientContractStatus[]).some(s => s === contract.status) &&
                  <Button variant='primary' className='mr-1'
                    disabled={this.state.isDownloading}
                    onClick={() => this.download(contract)}
                  ><Icon.Download /></Button>
                }
                {contract.status === 'generate' as TClientContractStatus &&
                  <Spinner variant='primary' animation='border' className='m-1' />
                }
                {!(['draft', 'generate'] as TClientContractStatus[]).some(s => s === contract.status) &&
                  <Button variant='primary' className='mr-1'
                    onClick={() => this.setState({contractPdf: contract})}
                  ><Icon.Eye /></Button>
                }
                {(['new', 'returned'] as TClientContractStatus[]).some(s => s === contract.status) &&
                  <>
                    <Button variant='primary' className='mr-1'
                      onClick={() => this.setState({contract})}
                    ><Icon.Pencil /></Button>
                    <Button variant='danger'
                      disabled={contract._id === this.state.removingContractId}
                      onClick={() => this.remove(contract, index)}
                    >
                      {this.state.removingContractId === contract._id
                        ? <Spinner animation='border' variant='light' size='sm' />
                        : <Icon.Trash />
                      }
                    </Button>
                  </>
                }
                {contract.files?.length &&
                  <Dropdown className='btn btn-primary files-box'>
                    <Dropdown.Toggle id={contract._id} as='div' className='files-toggle'>
                      <Icon.List />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {contract.files.filter((f: any) => f.show).map((file: any) =>
                        <Dropdown.Item key={file._id} className='files-item' onClick={() => this.downloadFile(file.name, file._id)}>
                          <span className='files-text'>{file.name}</span>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </td>
            </tr>)}
          </tbody>
        </Table>
        <Pagination>
          <Pagination.First disabled={this.state.page === 1} onClick={() => this.setState({page: 1}, () => this.updateContracts())} />
          <Pagination.Prev disabled={this.state.page === 1} onClick={() => this.setState({page: this.state.page - 1}, () => this.updateContracts())} />
          {this.state.pages.map(page => <Pagination.Item key={page} active={this.state.page === page}
            onClick={() => this.setState({page}, () => this.updateContracts())}>{page}</Pagination.Item>)}
          <Pagination.Next disabled={this.state.page === this.state.pagesCount} onClick={() => this.setState({page: this.state.page + 1}, () => this.updateContracts())} />
          <Pagination.Last disabled={this.state.page === this.state.pagesCount} onClick={() => this.setState({page: this.state.pagesCount}, () => this.updateContracts())} />
        </Pagination>
        <Contract
          show={this.state.contract !== undefined}
          onEnter={() => { }}
          onHide={(needUpdate) => {
            this.setState({contract: undefined});
            if (needUpdate) {
              this.updateContracts();
            }
          }}
          contract={this.state.contract || {}}
        />
        <ContractPdf
          show={this.state.contractPdf !== undefined}
          onEnter={() => { }}
          onHide={(needUpdate) => {
            this.setState({contractPdf: undefined});
            if (needUpdate) {
              this.updateContracts();
            }
          }}
          contract={this.state.contractPdf || {}}
        />
        <FormGroup>
          <Button color='primary'
            onClick={() => this.setState({contract: {}})}
          >{t['actions.contract.add']}</Button>
        </FormGroup>
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  i18n: state.i18n,
  user: state.identity.user
});

const Contracts = connect(mapStateToProps)(ContractsComponent);
export default Contracts;
