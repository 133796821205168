import {addQueryParams, headersWithAuth, processResponse} from '.';
import config from '../../config';

class Files {
  private static baseUrl = config.host + config.mod + '/client/files';

  public static download(token: string, filename: string) {
    const link = window.document.createElement('a');
    link.download = filename;
    const urlObj = this.baseUrl + '/name/' + filename + addQueryParams({token});
    link.href = urlObj;
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
    window.URL.revokeObjectURL(urlObj);
  }

  public static async getFileToken(fileId: string) {
    const tokenResponse = await fetch(this.baseUrl + '/token' + addQueryParams({fileId}), {
      method: 'GET',
      headers: await headersWithAuth()
    });
    return await processResponse(tokenResponse);
  }

  public static async getShowLink(fileId: string, filename: string) {
    const token = await this.getFileToken(fileId);
    const url = this.baseUrl + '/show/' + filename + addQueryParams({token, filename});
    return url;
  }

  public static async getDownloadLink(fileId: string, filename: string, token?: string) {
    const _token = token || await this.getFileToken(fileId);
    const url = this.baseUrl + '/show/' + filename + addQueryParams({token: _token, filename});
    return url;
  }

  public static async getLink(fileId: string, filename: string) {
    const url = await this.getShowLink(fileId, filename);
    const link = window.document.createElement('a');
    link.href = url;
    link.target = '_blank';
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  public static async get(fileId: string) {
    return await fetch(this.baseUrl + '/' + fileId, {
      method: 'GET',
      headers: await headersWithAuth()
    });
  }

  public static async getBuffer(fileId: string) {
    const response = await this.get(fileId);
    return Buffer.from(await response.arrayBuffer());
  }

  public static async getBlob(fileId: string) {
    const response = await this.get(fileId);
    return await response.blob();
  }
}

export default Files;
