export const savedRun = async (func: () => Promise<any>) => {
  try {
    return await func();
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const getGroupedOptions = (options: any[], fieldName: string) => {
  const keys: any = {};
  options.forEach(option => {
    let value = keys[option[fieldName]];
    if (!value) {
      value = keys[option[fieldName]] = [];
    }
    value.push(option);
  });
  return Object.keys(keys).map(key => ({
    label: key,
    options: keys[key]
  })).sort((a, b) => a.label > b.label ? 1 : -1);
}

export const gtag = (pagePath: string) => {
  const w = window as any;
  if (w.dataLayer) {
    w.dataLayer.push(['js', new Date()]);
    if (pagePath) {
      w.dataLayer.push(['config', 'G-6TFHCD0SX0', { 'page_path': pagePath }]);
    } else {
      w.dataLayer.push(['config', 'G-6TFHCD0SX0']);
    }
  }
};
