import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import 'moment/min/locales';
import {i18nMapStateToProps} from '../../../i18n';
import {BaseI18nComponentProps} from '../../../App';
import {WeatherIcon} from '../../../shared/server/weather';
import getIconAndClass from './weatherIcon';
import sunIcon from '../../../assets/img/weather/sun.png';

interface InternalProps extends BaseI18nComponentProps {
  temp: number;
  weatherIcon: WeatherIcon;
  date: Date;
  wind: number;
  className?: string;
}

class ModelWidgetComponent extends React.PureComponent<InternalProps> {

  private getTempString(temp: number, temp2?: number) {
    const tempStr = (temp: number) => (temp < 0 ? '' : '+') + Math.round(temp) + '°';
    let result = tempStr(temp);
    if (temp2 || temp2 === 0) {
      result += ' ' + tempStr(temp2);
    }
    return (<span>{result}</span>);
  }

  render() {
    const t = this.props.i18n.t;
    const r = getIconAndClass(this.props.weatherIcon);
    let iconSrc = r.iconSrc;
    const bgClass = r.bgClass;
    if (bgClass === 'weather-clear') {
      iconSrc = sunIcon;
    }
    return (
      <div className={'model-widget-box' + (this.props.className ? ' ' + this.props.className : '')}>
        <div className='weather-icon-box'>
          <img src={iconSrc} className='weather-icon' alt='icon' />
        </div>
        <div className='temp-box'>
          <div className='temp-text'>{this.getTempString(this.props.temp)}</div>
        </div>
        <div className='wind-box'>
          <div className='wind-text'>{this.props.wind.toFixed(0)}{t['weather_unit_speed']}</div>
        </div>
        <div className='date-box'>
          <div className='date-text'>{moment(this.props.date).format('HH:mm')}</div>
        </div>
      </div>
    );
  }
}

const ModelWidget = connect(i18nMapStateToProps)(ModelWidgetComponent);

export default ModelWidget;
