const yandexMapOptions = {
  defaultOptions: {
    restrictMapArea: true
  },
  defaultState: {
    bounds: [[40, 46], [56, 88]]
  }
};

export default yandexMapOptions;
