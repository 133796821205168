import React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Container, Row, Col, Spinner, Collapse, Image} from 'react-bootstrap';
import {BaseAllComponentProps} from '../../App';
import Select from 'react-select';
import AppSelectStyles from '../../shared/components/plain/select/selectStyles';
import Server from '../../shared/server';
import WeatherWidget from './widget/widget';
import {AppState, IUser} from '../../store';
import {IGeoLocationState, GeoLocationActions} from '../../shared/geoLocation';
import {getGroupedOptions, savedRun} from '../../shared/utils';
import CKEditorPage from '../../shared/components/ckeditorPage/ckeditorPage';
import moment from 'moment';
import ModelWidget from './widget/modelWidget';

interface InternalProps extends BaseAllComponentProps {
  user?: IUser;
  geoLocation: IGeoLocationState;
  updateGeolocation: (geoLocation?: IGeoLocationState) => Promise<void>;
}
interface InternalState {
  towns: any[];
  town?: any;
  forecast?: any[];
  wrf?: any;
  townsLoading: boolean;
  townLoading: boolean;
  textLoading: boolean;
  content: string;
  showModel: boolean;

  isMeteogramsLoading: boolean;
  meteograms: any[];
}

class WeatherPageComponent extends React.PureComponent<InternalProps, InternalState> {

  constructor(props: InternalProps) {
    super(props);
    this.state = {
      townsLoading: true,
      townLoading: true,
      textLoading: true,
      towns: [],
      content: '',
      showModel: false,

      isMeteogramsLoading: false,
      meteograms: []
    };
  }

  private updateMeteograms() {
    savedRun(async () => {
      try {
        this.setState({isMeteogramsLoading: true});
        const meteograms = await Server.Client.getMeteograms();
        await Promise.all(meteograms.map(async (m: any) => {
          m.link = await Server.Files.getDownloadLink(m.fileId, m.filename, m.token);
        }));
        this.setState({meteograms});
        // const townName = meteograms[0]?.town?.nameRu;
        // if (townName) {
        //   const town = this.state.towns.find(t => t.townNameRu === townName);
        //   console.log(town);
        //   if (town) {
        //     this.setTown(town._id);
        //   }
        // }
      } finally {
        this.setState({isMeteogramsLoading: false});
      }
    });
  }

  async componentDidMount() {
    if (!!this.props.user) {
      this.updateMeteograms();
    }
    const towns = await Server.Weather.getTowns();
    const grouped = getGroupedOptions(towns, 'regionName' + this.props.i18n.t['langUp']);
    this.setState({towns: grouped, townsLoading: false});
    if (this.props.geoLocation.success === undefined) {
      savedRun(async () => this.props.updateGeolocation());
    } else if (this.props.geoLocation.success && this.props.geoLocation.position) {
      const result = await Server.Weather.getTownByPosition(this.props.geoLocation.position, this.props.i18n.lang);
      if (result) {
        this.setState({town: result.actual, forecast: result.forecast, content: result.content, townLoading: false});
        this.updateWrf(result.wrf);
      } else {
        this.setState({townLoading: false});
      }
    }
  }

  componentDidUpdate(prevProps: InternalProps) {
    if (!prevProps.user && !!this.props.user) {
      this.updateMeteograms();
    }
    if (prevProps.i18n.lang !== this.props.i18n.lang) {
      let towns: any[] = [];
      this.state.towns.forEach(town => {
        towns = towns.concat(town.options);
      });
      const grouped = getGroupedOptions(towns, 'regionName' + this.props.i18n.t['langUp']);
      this.setState({towns: grouped});
    }
    if (prevProps.geoLocation.success === undefined && this.props.geoLocation.success !== undefined && !this.state.town) {
      if (this.props.geoLocation.success === true) {
        savedRun(async () => {
          if (!this.props.geoLocation.position) {
            return;
          }
          const result = await Server.Weather.getTownByPosition(this.props.geoLocation.position, this.props.i18n.lang);
          if (result) {
            this.setState({town: result.actual, forecast: result.forecast, content: result.content, townLoading: false});
            this.updateWrf(result.wrf);
          } else {
            this.setState({townLoading: false});
          }
        });
      } else {
        this.setState({townLoading: false});
      }
    }
  }

  setTown(id: string) {
    savedRun(async () => {
      const result = await Server.Weather.getTown(id, this.props.i18n.lang);
      if (result) {
        this.setState({town: result.actual, forecast: result.forecast, content: result.content, townLoading: false});
        this.updateWrf(result.wrf);
      }
    });
  }

  getTownLabel(town: any) {
    return this.props.i18n.lang === 'kk'
      ? town.townNameKk
      : town.townNameRu;
  }

  updateContent() {
    this.setState({textLoading: true});
    savedRun(async () => {
      try {
        const content = await Server.Pages.get('weather');
        this.setState({content, textLoading: false});
      } catch (e) {
        this.setState({textLoading: false});
        throw e;
      }
    });
  }

  private updateWrf(wrf?: any) {
    if (!wrf) {
      this.setState({wrf: undefined});
      return;
    }
    const result: any = {};
    wrf.forecast.filter((w: any) => moment(w.date).isAfter(moment())).forEach((wrf: any) => {
      const date = moment(wrf.date).format('yyyy-MM-DD');
      if (!result[date]) {
        result[date] = [wrf];
      } else {
        result[date].push(wrf);
      }
    });
    this.setState({wrf: result});
  }

  render() {
    const t = this.props.i18n.t;
    const lang = this.props.i18n.lang;
    const town = this.state.town;
    if (town && (
      !town.soilTemperatureDate ||
      moment(town.soilTemperatureDate).add(3, 'hours').isBefore(moment())
    )) {
      delete town.soilTemperature;
    }
    return (
      <Container fluid='xl' className='weather-box'>
        <div className='town-box'>
          <Select styles={AppSelectStyles} isSearchable placeholder={t['location']}
            isLoading={this.state.townsLoading}
            options={this.state.towns}
            getOptionLabel={(option: any) => this.getTownLabel(option)}
            getOptionValue={(option: any) => option.id}
            value={this.state.town}
            onChange={(option) => this.setTown(option._id)} />
        </div>
        {this.state.townLoading &&
          <div className='d-flex justify-content-center pt-5'>
            <Spinner animation='grow' variant='secondary' />
          </div>
        }
        {this.state.town &&
          <div className='main-widget-box'>
            <div className='main-widget'>
              <WeatherWidget
                cityName={lang === 'kk' ? town.townNameKk : town.townNameRu}
                date={town.date}
                humidity={town.humidity}
                pressure={town.pressure}
                temperature={town.temperature}
                weatherIcon={town.weatherIcon}
                windDirection={town.wind.direction}
                windSpeed={town.wind.speed}
                soilTemperature={town.soilTemperature}
              />
            </div>
          </div>
        }
        {this.state.wrf &&
          <div className='model-box'>
            <div className='model-header-text'
              onClick={() => this.setState({showModel: !this.state.showModel})}
            >
              <div className='header'>{t['weather.model.header']}</div>
              <div className='sub-header'>{t['weather.model.subheader']}</div>
            </div>
            <div className='d-block'>
              <Collapse in={this.state.showModel} >
                <div>
                  <div className='model-content-box'>
                    {Object.keys(this.state.wrf).map((key: string, i: number) =>
                      <div className='wrf-date-box' key={key}>
                        {moment(key).format('yyyy-MM-DD')}
                        <div className='wrf-box'>
                          {this.state.wrf[key].map((f: any, i: number) =>
                            <ModelWidget key={i}
                              className={'model-widget'}
                              {...f}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        }
        {this.state.forecast && this.state.forecast.length > 0 &&
          <>
            <Row className='p-0 m-0 justify-content-center'>
              {this.state.forecast.filter(f => f.townNameRu).map((f: any, index: number) =>
                <Col key={index} lg='4' md='6' sm='9' className='pt-3'>
                  <WeatherWidget isForecast
                    cityName={lang === 'kk' ? f.townNameKk : f.townNameRu}
                    date={f.date}
                    temperatureDayMin={f.temperatureDayMin}
                    temperatureDayMax={f.temperatureDayMax}
                    temperatureNightMin={f.temperatureNightMin}
                    temperatureNightMax={f.temperatureNightMax}
                    weatherIcon={f.weatherIcon}
                    windDirection={f.wind.direction}
                    windSpeed={f.wind.speedMin}
                    windSpeed2={f.wind.speedMax}
                  />
                </Col>
              )}
            </Row>
            <div className='region-name'>{lang === 'kk' ? this.state.town.regionNameKk : this.state.town.regionNameRu}</div>
            <Row className='p-0 m-0 justify-content-center'>
              {this.state.forecast.filter(f => !f.townNameRu).map((f: any, index: number) =>
                <Col key={index} lg='4' md='6' sm='9' className='pt-3'>
                  <WeatherWidget isForecast
                    cityName={lang === 'kk' ? f.townNameKk : f.townNameRu}
                    date={f.date}
                    temperatureDayMin={f.temperatureDayMin}
                    temperatureDayMax={f.temperatureDayMax}
                    temperatureNightMin={f.temperatureNightMin}
                    temperatureNightMax={f.temperatureNightMax}
                    weatherIcon={f.weatherIcon}
                    windDirection={f.wind.direction}
                    windSpeed={f.wind.speedMin}
                    windSpeed2={f.wind.speedMax}
                  />
                </Col>
              )}
            </Row>
          </>
        }
        {this.state.content &&
          <div className='text'>
            <CKEditorPage content={this.state.content} />
          </div>
        }
        {(this.props.user && this.state.meteograms.length > 0) && this.state.meteograms.map((meteogram: any) => (
          <div key={meteogram._id} className='meteogram-box'>
            <div className='title'>
              {meteogram['title' + t['langUp']]}
            </div>
            <div className='date'>
              {moment(meteogram.date).format('DD.MM.YYYY')}
            </div>
            <Image src={meteogram.link} className='image' />
          </div>
        ))}
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  i18n: state.i18n,
  user: state.identity.user,
  geoLocation: state.geoLocation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateGeolocation: GeoLocationActions.setGeoLocation(dispatch)
});

const WeatherPage = connect(mapStateToProps, mapDispatchToProps)(WeatherPageComponent);

export default WeatherPage;
