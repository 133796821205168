import React from 'react';
import logo from '../../assets/img/logo.png';
import flagKkSrc from '../../assets/img/flagKk.png';
import flagRuSrc from '../../assets/img/flagRu.png';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { routes, BaseAllComponentProps, Routes, ClientRoutes } from '../../App';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState, IUser } from '../../store';
import { Actions, Lang } from '../../i18n';
import Login from '../login/login';
import Server from '../../shared/server';
import { gtag, savedRun } from '../../shared/utils';
import { IdentityActions } from '../../store';
import ChangePassword from './changePassword';

interface InternalProps extends BaseAllComponentProps {
  user?: IUser;
  setLang: (lang: Lang) => void;
  updateUser: (user?: IUser) => void;
}

interface InternalState {
  showLogin: boolean;
  changePasswordShow: boolean;
  expanded: boolean;
}

let currentLocation = '/';

class NavBarComponent extends React.PureComponent<InternalProps, InternalState> {

  constructor(props: InternalProps) {
    super(props);
    this.state = {
      showLogin: false,
      changePasswordShow: false,
      expanded: false
    };
  }

  componentDidMount() {
    if (Object.keys(this.props.i18n.t)) {
      const lang = localStorage.getItem('lang') as Lang;
      this.props.setLang(lang || 'kk');
    }
    savedRun(async () => {
      const user = await Server.getUser();
      if (!user && ClientRoutes.some(cr => cr === this.props.location.pathname)) {
        this.props.history.push(Routes.Home);
      }
      this.props.updateUser(user);
    });
    this.props.history.listen((event) => this.setState({ expanded: false }));
  }

  componentDidUpdate(prevProps: InternalProps) {
    if (prevProps.user && !this.props.user && ClientRoutes.some(cr => cr === this.props.location.pathname)) {
      this.props.history.push(Routes.Home);
    }
    if (currentLocation !== this.props.location.pathname) {
      currentLocation = this.props.location.pathname;
      gtag(currentLocation);
    }
  }

  setLang(lang: Lang) {
    this.props.setLang(lang);
  }

  private logout() {
    Server.logout();
    this.props.updateUser();
  }

  render() {
    const t = this.props.i18n.t;
    return (
      <Navbar fixed='top' expand='lg' className='my-navbar' variant='dark' expanded={this.state.expanded} >
        <Container fluid='lg'>
          <Navbar.Brand className='icon-box'><img src={logo} height='38rem' alt='' /></Navbar.Brand>
          <div className='mobile-label'>AgroData</div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => this.setState({ expanded: !this.state.expanded })} />
          <Navbar.Collapse id="basic-navbar-nav" className='justify-content-between my-collapse'>
            <Nav className='w-100 justify-content-center'>
              {routes.filter(r => !!this.props.user || r.authOnly !== true).map((route, i) =>
                route.childs
                  ? <Dropdown key={i}>
                    <Dropdown.Toggle id={route.path} as='div'
                      className={'my-dropdown my-navlink ' + (this.props.location.pathname.indexOf(route.path) === 0 ? 'active' : '')}>
                      <div className='text'>{t[route.text]}</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {route.childs.filter(r => !!this.props.user || r.authOnly !== true).map((routeChild, i) =>
                        <Dropdown.Item key={i} as={NavLink} to={route.path + routeChild.path} activeClassName='active' className='my-navlink'>
                          <span className='text'>{t[routeChild.text]}</span>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  : <Nav.Link key={i} as={NavLink} to={route.path} activeClassName='active' className='my-navlink'>
                    <div className='text'>{t[route.text]}</div>
                  </Nav.Link>
              )}
            </Nav>
            <Nav>
              {!this.props.user
                ? <Nav.Item className='my-navlink'
                  onClick={() => {
                    // if (window.prompt('Функция на тестировании, введите пароль') === '09112020') {
                    this.setState({ showLogin: true });
                    // }
                  }}
                >
                  <span className='text'>{t['nav.login']}</span>
                </Nav.Item>
                : <Dropdown>
                  <Dropdown.Toggle id='user' as='div'
                    className={'my-dropdown my-navlink ' + (this.props.location.pathname.indexOf(Routes.Contracts) === 0 ? 'active' : '')}>
                    <div className='text'>{t['menu']}</div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to={Routes.Contracts} className='my-navlink'>
                      <span className='text'>{t['nav.contracts']}</span>
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to={Routes.BalanceChanges} className='my-navlink'>
                      <span className='text'>{t['nav.balance']}</span>
                    </Dropdown.Item>
                    <Dropdown.Item as='div' className='my-navlink' onClick={() => this.setState({ changePasswordShow: true })}>
                      <span className='text'>{t['actions.changePassword']}</span>
                    </Dropdown.Item>
                    <Dropdown.Item as='div' className='my-navlink' onClick={() => this.logout()}>
                      <span className='text'>{t['actions.logout']}</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }
            </Nav>
            <Nav>
              <Nav.Item className={'my-navlink ' + (this.props.i18n.lang === 'kk' && 'active')} onClick={() => this.setLang('kk')}>
                <div className='text-nowrap d-flex align-items-center' >
                  {/* <span className='text'></span>&nbsp; */}
                  <img src={flagKkSrc} alt='kk' />
                </div>
              </Nav.Item>
              <Nav.Item className={'my-navlink ' + (this.props.i18n.lang === 'ru' && 'active')} onClick={() => this.setLang('ru')}>
                <div className='text-nowrap d-flex align-items-center'>
                  {/* <span className='text'>РУС</span>&nbsp; */}
                  <img src={flagRuSrc} alt='ru' />
                </div>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <ChangePassword show={this.state.changePasswordShow} onHide={() => this.setState({ changePasswordShow: false })} />
        <Login show={this.state.showLogin} onHide={() => this.setState({ showLogin: false })} />
      </Navbar >
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  i18n: state.i18n,
  user: state.identity.user
});

const dispatchToProps = (dispatch: Dispatch) => ({
  setLang: Actions.setLang(dispatch),
  updateUser: IdentityActions.updateUser(dispatch)
});

const NavBar = connect(mapStateToProps, dispatchToProps)(NavBarComponent);

export default NavBar;
