import {Dispatch} from 'react';
import {createStore, combineReducers} from 'redux';
import {I18nState, i18nReducer, I18nActionType} from './i18n';
import {IGeoLocationState, geoLocationReducer, GeoLocationActionType} from './shared/geoLocation';

export interface IUser {
  id: string;
  bin: string;
}

export interface IdentityState {
  user?: IUser;
  updated: boolean;
}

const defaultState: IdentityState = {updated: false};

export enum IdentityActionType {
  updateUser = 'updateUser'
}

export class IdentityActions {
  public static updateUser = (dispatch: Dispatch<IAction>) => (user?: IUser) => {
    dispatch({type: IdentityActionType.updateUser, payload: user});
  }
}

export const IdentityReducer = (state: IdentityState = defaultState, action: IAction): IdentityState => {
  switch (action.type) {
    case IdentityActionType.updateUser: {
      return {...state, user: action.payload, updated: true};
    }
    default: return state;
  }
}

export type ActionType = I18nActionType | GeoLocationActionType | IdentityActionType;

export interface IAction {
  type: ActionType,
  payload: any
}

export interface AppState {
  i18n: I18nState;
  geoLocation: IGeoLocationState;
  identity: IdentityState;
}

const reducer = combineReducers({
  identity: IdentityReducer,
  i18n: i18nReducer,
  geoLocation: geoLocationReducer
});

const store = createStore(reducer);
export default store;
