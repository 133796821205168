import React from 'react';
import {Dispatch} from 'redux';
import {BaseAllComponentProps} from '../../App';
import {connect} from 'react-redux';
import {Spinner, Container, Card, Table} from 'react-bootstrap';
// import {YMaps, Map, Placemark} from 'react-yandex-maps';
// import yandexMapOptions from '../../shared/options/mapOptions';
import {savedRun} from '../../shared/utils';
import Server from '../../shared/server';
import Select from 'react-select';
import AppSelectStylesCalendar from '../calendar/appSelectStylesCalendar';
import {IGeoLocationState, GeoLocationActions} from '../../shared/geoLocation';
import {AppState} from '../../store';
import moment from 'moment';

interface InternalProps extends BaseAllComponentProps {
  geoLocation: IGeoLocationState;
  updateGeolocation: (geoLocation?: IGeoLocationState) => Promise<void>;
}

interface InternalState {
  moisturePoints: any[];
  isLoading: boolean;
  legendVisible: boolean;
  selectedPoints: any[];
}

class ActualMoistureComponent extends React.PureComponent<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      moisturePoints: [],
      selectedPoints: [],
      isLoading: true,
      legendVisible: false
    };
  }

  // private map?: any;

  componentDidMount() {
    savedRun(async () => {
      try {
        const result = await Server.ActualMoisture.getData();
        this.setState({moisturePoints: result.entities, isLoading: false});
        if (this.props.geoLocation.success) {
          this.setByPosition();
        }
      } finally {
        this.setState({isLoading: false});
      }
    });
    if (this.props.geoLocation.success === undefined) {
      savedRun(async () => await this.props.updateGeolocation());
    }
  }

  componentDidUpdate(prevProps: InternalProps) {
    if (prevProps.geoLocation.success !== true && this.props.geoLocation.success === true &&
      (!this.state.selectedPoints || this.state.selectedPoints.length === 0)) {
      this.setByPosition();
    }
  }

  private setByPosition() {
    const deg2rad = (deg: number) => {
      return deg * (Math.PI / 180)
    }
    const getDistanceFromLonLatInKm = (first: number[], second: number[]) => {
      const lon1 = first[0];
      const lat1 = first[1];
      const lon2 = second[0];
      const lat2 = second[1];
      var R = 6371; // Radius of the earth in km
      var dLat = deg2rad(lat2 - lat1);  // deg2rad below
      var dLon = deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in km
      return d;
    }
    const position = this.props.geoLocation.position;
    if (!position) {
      return;
    }
    const posCoords = [position.coords.longitude, position.coords.latitude];
    let nearest: any;
    let distance = 100;
    this.state.moisturePoints.forEach(p => {
      if (!p.town.geometry?.coordinates) {
        return;
      }
      const d = getDistanceFromLonLatInKm(posCoords, p.town.geometry.coordinates);
      if (d < distance) {
        distance = d;
        nearest = p;
      }
    });
    if (nearest && this.state.selectedPoints.length === 0) {
      this.setState({selectedPoints: [nearest]});
    }
  }

  // private clickOnPlaceMark(entity: any) {
  //   const index = this.state.selectedPoints.indexOf(entity);
  //   if (index > -1) {
  //     this.state.selectedPoints.splice(index, 1);
  //     this.setState({selectedPoints: [...this.state.selectedPoints]});
  //   } else {
  //     this.setState({selectedPoints: [...this.state.selectedPoints, entity]});
  //   }
  // }

  private addressByTown(town: any) {
    const fieldName = 'name' + this.props.i18n.t['langUp'];
    return [
      town?.[fieldName],
      town?.district?.[fieldName],
      town?.district?.region?.[fieldName]
    ].filter(n => n).join(', ');
  }

  private dateRangeToStr(obj: any) {
    const from = moment(obj.dateFrom);
    const to = moment(obj.dateTo);
    let result = '';
    if (from.month() !== to.month()) {
      result += from.format('D MMMM');
    } else {
      result += from.date().toString();
    }
    if (from.year() !== to.year()) {
      result += ' ' + from.year().toString();
    }
    result += ' - ' + to.format('D MMMM YYYY');
    return result;
  }

  render() {
    const t = this.props.i18n.t;
    return (
      <Container fluid='lg' className='p-0 actual-moisture-box'>
        <div className='select-box'>
          <Select styles={AppSelectStylesCalendar} isSearchable={true} isMulti
            isLoading={this.state.isLoading}
            placeholder={t['location']}
            options={this.state.moisturePoints}
            getOptionLabel={(option: any) => this.addressByTown(option.town)}
            getOptionValue={(option: any) => option._id}
            value={this.state.selectedPoints}
            onChange={(option) => this.setState({selectedPoints: option || []})} />
        </div>
        {this.state.isLoading &&
          <div className='moisture-spinner'>
            <Spinner animation='grow' variant='primary' />
          </div>
        }
        {/* <div className='map-box'>
            <YMaps>
              <Map instanceRef={(ref: any) => this.map = ref}
                onLoad={() => this.setState({isLoading: false})} height='100%' width='100%'
                {...yandexMapOptions}
                modules={['geoObject.addon.balloon']}
              >
                {this.state.moisturePoints.map((station, key) =>
                  <Placemark key={key}
                    onClick={() => this.clickOnPlaceMark(station)}
                    defaultOptions={{
                      preset: 'islands#circleDotIcon'
                    }}
                    defaultGeometry={[station.town.geometry.coordinates[1], station.town.geometry.coordinates[0]]}
                  />
                )}
              </Map>
            </YMaps>
          </div> */}
        <div className='widget-box'>
          {this.state.selectedPoints.map((p, i) =>
            <Card key={i}>
              <Card.Header>
                {this.addressByTown(p.town)}
              </Card.Header>
              <Card.Body>
                <div className='date-range'>{this.dateRangeToStr(p)}</div>
                <Table striped bordered>
                  <tbody>
                    {p.activeTSum && p.activeTSum !== '' &&
                      <tr>
                        <td>{t['actual.moisture.fields.activeTSum']}, °C</td>
                        <td>{p.activeTSum}°</td>
                      </tr>
                    }
                    {p.effectiveTSum && p.effectiveTSum !== '' &&
                      <tr>
                        <td>{t['actual.moisture.fields.effectiveTSum']}, °C</td>
                        <td>{p.effectiveTSum}°</td>
                      </tr>
                    }
                    {p.moistureFirst && p.moistureFirst !== '' &&
                      <tr>
                        <td>{t['actual.moisture.fields.moistureFirst']}</td>
                        <td>{p.moistureFirst}{t['unit.mm.short']}</td>
                      </tr>
                    }
                    {p.moistureSecond && p.moistureSecond !== '' &&
                      <tr>
                        <td>{t['actual.moisture.fields.moistureSecond']}</td>
                        <td>{p.moistureSecond}{t['unit.mm.short']}</td>
                      </tr>
                    }
                    {p.depthOfFroze && p.depthOfFroze !== '' &&
                      <tr>
                        <td>{t['actual.moisture.fields.depthOfFroze']}</td>
                        <td>{p.depthOfFroze}{t['unit.cm.short']}</td>
                      </tr>
                    }
                    {p.depthOfThawing && p.depthOfThawing !== '' &&
                      <tr>
                        <td>{t['actual.moisture.fields.depthOfThawing']}</td>
                        <td>{p.depthOfThawing}{t['unit.cm.short']}</td>
                      </tr>
                    }
                    {p.heightOfSnow && p.heightOfSnow !== '' &&
                      <tr>
                        <td>{t['actual.moisture.fields.heightOfSnow']}</td>
                        <td>{p.heightOfSnow}{t['unit.cm.short']}</td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  i18n: state.i18n,
  geoLocation: state.geoLocation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateGeolocation: GeoLocationActions.setGeoLocation(dispatch)
});

const ActualMoisture = connect(mapStateToProps, mapDispatchToProps)(ActualMoistureComponent);

export default ActualMoisture;
