import React from 'react';
import {Container} from 'react-bootstrap';
import {connect} from 'react-redux';
import {BaseAllComponentProps} from '../../App';
import {i18nMapStateToProps} from '../../i18n';
import CKEditorPage from '../../shared/components/ckeditorPage/ckeditorPage';

interface InternalProps extends BaseAllComponentProps { }
interface InternalState {

}

class InstructionComponent extends React.PureComponent<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {

    };
  }

  render() {
    return <Container fluid='lg' className='instruction-box'>
      <CKEditorPage pageName={'instruction'} />
    </Container>;
  }
}

const Instruction = connect(i18nMapStateToProps)(InstructionComponent);
export default Instruction;
