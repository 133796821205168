import config from '../../config';
import {processResponse} from '.';

class ForecastsMoisture {
  public static baseUrl = config.host + config.mod + config.forecastMoisture;

  public static async getData() {
    const response = await fetch(
      this.baseUrl,
      {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      }
    );
    const result = await processResponse(response);
    return result as any[];
  }

}

export default ForecastsMoisture;
