import React from 'react';

export enum YaPolygonColor {
  Low = '#bbbb0080',
  Middle = '#00bb0080',
  High = '#00bbcc80'
}

// const avg = (valueFrom + valueTo) / 2;
// if (avg < 8) return '#cfece770';
// if (avg < 9) return '#73c6b570';
// if (avg < 10) return '#169f8570';
// if (avg < 11) return '#127a6570';
// return '#09534570';

interface InternalProps {
  color: YaPolygonColor;
}

class YaPolygon extends React.PureComponent<InternalProps> {
  render() {
    return (
      <div className='polygon-box' style={{backgroundColor: this.props.color}} />
    );
  }
}

export default YaPolygon;
