import AppSelectStyles from '../../shared/components/plain/select/selectStyles';
import {StylesConfig} from 'react-select';

const hoveredOption = {
  background: '#5bbaf9'
}

const AppSelectStylesCalendar: StylesConfig = {
  ...AppSelectStyles,
  container: (provided, state) => ({
    ...provided, ...(AppSelectStyles.container ? AppSelectStyles.container(provided, state) : {}),
    background: 'linear-gradient(0deg, #cfe4ea, #eff4fa)'
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#e0e7edcc'
  }),
  dropdownIndicator: (provided, state) => provided,
  placeholder: (provided, state) => provided,
  valueContainer: (provided, state) => ({
    ...provided, ...(AppSelectStyles.valueContainer ? AppSelectStyles.valueContainer : {}),
    paddingTop: 0, paddingBottom: 0
  }),
  singleValue: (provided, state) => ({
    ...provided, color: '#000000'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#53acdb',
    background: 'linear-gradient(180deg, #3c88c3, #0762a3)',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontFamily: 'Myriad Pro',
    fontSize: '1.2em',
    color: '#ffffff'
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    ':hover': {
      backgroundColor: '#3b9ad9'
    },
  }),
  option: (provided, state) => {
    const p = {
      ...provided,
      color: '#000000',
      ':active': hoveredOption
    };
    if (state.isFocused) {
      return {...p, ...hoveredOption};
    }
    return p;
  },
  input: (provided, state) => ({
    ...provided, ...(AppSelectStyles.valueContainer ? AppSelectStyles.valueContainer : {}),
    color: '#000000'
  }),
};

export default AppSelectStylesCalendar;
