import React from 'react';
import Select, {Props} from 'react-select';

interface InternalProps extends Props<any> {
}

class CustomSelect extends React.PureComponent<InternalProps> {
  render() {
    return (
      <Select
        {...this.props}
        styles={{
          ...this.props.styles,
          control: (styles, state) => ({
            ...this.props.styles?.control?.(styles, state) || styles,
            borderColor: this.props.className && this.props.className !== ''
              ? this.props.className.indexOf('is-invalid') > -1
                ? '#db2a3e' : this.props.className.indexOf('is-valid') > -1
                  ? '#179e45' : styles.borderColor
              : styles.borderColor
          })
        }}
      />
    );
  }
}

export default CustomSelect;
