import React from 'react';
import {Container, Spinner} from 'react-bootstrap';
import {connect} from 'react-redux';
import {BaseAllComponentProps} from '../../App';
import {i18nMapStateToProps} from '../../i18n';
import CKEditorPage from '../../shared/components/ckeditorPage/ckeditorPage';
import Server from '../../shared/server';
import {savedRun} from '../../shared/utils';
import {AppState, IUser} from '../../store';
import Select from 'react-select';
import AppSelectStylesCalendar from '../calendar/appSelectStylesCalendar';
import moment from 'moment';

interface InternalProps extends BaseAllComponentProps {
  user?: IUser;
}

interface InternalState {
  isTextsLoading: boolean;
  texts: any[];
}

class ClientTextComponent extends React.PureComponent<InternalProps, InternalState> {

  constructor(props: InternalProps) {
    super(props);
    this.state = {
      isTextsLoading: false,
      texts: []
    };
  }

  private updateTexts() {
    savedRun(async () => {
      try {
        this.setState({isTextsLoading: true});
        const texts = await Server.Client.getTexts();
        texts.forEach((t: any) => {
          t.dates = t.dates.map((d: string) => ({value: d, text: moment(d).format('YYYY-MM-DD')}));
        });
        this.setState({texts});
      } finally {
        this.setState({isTextsLoading: false});
      }
    });
  }

  componentDidMount() {
    this.updateTexts();
  }

  private setDate(text: any, date: string) {
    savedRun(async () => {
      try {
        text.isLoading = true;
        this.setState({texts: [...this.state.texts]});
        text.last = await Server.Client.getText(text._id, date);
      } finally {
        text.isLoading = false;
        this.setState({texts: [...this.state.texts]});
      }
    });
  }

  render() {
    const t = this.props.i18n.t;
    return (
      <Container fluid='lg' className='client-text-box'>
        {this.state.isTextsLoading &&
          <div className='loading-box'>
            <Spinner animation='grow' variant='primary' />
          </div>
        }
        {this.state.texts.map(text =>
          <div key={text._id}>
            <div className='head'>
              {text.last?.['title' + t['langUp']]}
            </div>
            {text.dates.find((d: string) => d === text.last?.date)}
            <Select styles={AppSelectStylesCalendar} isSearchable placeholder={t['fields.date.placeholder']}
              hideSelectedOptions
              isDisabled={text.isLoading}
              isLoading={text.isLoading}
              options={text.dates}
              getOptionLabel={(option: any) => option.text}
              getOptionValue={(option: any) => option.value}
              value={text.dates.find((d: any) => d.value === text.last?.date)}
              onChange={(option) => this.setDate(text, option.value)} />
            <div className='content'>
              <CKEditorPage customStyle
                content={text.last?.['content' + t['langUp']]}
              />
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  ...i18nMapStateToProps(state),
  user: state.identity.user
});

const ClientText = connect(mapStateToProps)(ClientTextComponent);
export default ClientText;
