import React from 'react';
import {Spinner} from 'react-bootstrap';

interface InternalProps {
  show: boolean;
  text?: string;
  variant?: 'light' | 'primary' | 'secondary';
  className?: string;
}

class CoverSpinner extends React.PureComponent<InternalProps> {
  render() {
    return (
      this.props.show &&
      <div className={'spinner-box' + (this.props.className ? ' ' + this.props.className : '')}>
        <Spinner animation='border' role='status' variant={this.props.variant ?? 'light'} />
        {this.props.text &&
          <div className='text'>{this.props.text}</div>
        }
      </div>
    );
  }
}

export default CoverSpinner;
