import React from 'react';
import {Button, Col, Form, FormControl, FormGroup, Modal, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {BaseI18nComponentProps} from '../../App';
import {i18nMapStateToProps} from '../../i18n';
import NCALayer from '../../shared/ncalayer';
import {savedRun} from '../../shared/utils';
import Server from '../../shared/server';
import {ValidationFieldError, ValidationSchemaName} from '../../shared/server/validation';
import CoverSpinner from '../../shared/components/plain/spinner/spinner';
import MaskedInput from '../../shared/components/plain/maskedinput/maskedinput';
import Select from 'react-select';
import {ClientType} from '../../shared/server/client';
import Feedback from 'react-bootstrap/Feedback';
import ReactDatePicker from 'react-datepicker';

interface InternalProps extends BaseI18nComponentProps {
  show: boolean;
  onHide: () => void;
}
interface InternalState {
  isLoading: boolean;
  refTowns: any[],
  clientTypes: any[],
  isTownsLoading: boolean;
  isNcaLoading: boolean;

  validated: boolean;
  validation?: any;
  isRegistration: boolean;
  status: 'edit' | 'validated' | 'signed';

  password?: string;
  confirm?: string;

  type: ClientType;

  towns: any[];
  name?: string;
  ceo?: string;
  address?: string;
  email?: string;
  phone?: string;
  iik?: string;
  bin?: string;
  bik?: string;
  kbe?: string;

  bases: any[];

  basisType?: any;
  basis?: string;
  basisDate?: Date;

  signed?: string;
}

class LoginComponent extends React.PureComponent<InternalProps, InternalState> {

  constructor(props: InternalProps) {
    super(props);
    const clientTypes = [
      {value: ClientType.FIZ, text: 'fields.type.' + ClientType.FIZ},
      {value: ClientType.INDIVIDUAL, text: 'fields.type.' + ClientType.INDIVIDUAL},
      {value: ClientType.ORGANIZATION, text: 'fields.type.' + ClientType.ORGANIZATION}
    ];
    this.state = {
      refTowns: [],
      clientTypes,
      isTownsLoading: false,
      status: 'edit',
      isLoading: false,
      isNcaLoading: false,
      validated: false,
      isRegistration: false,

      towns: [],
      type: ClientType.ORGANIZATION,

      bases: []

      // name: 'Company Name',
      // password: '123',
      // confirm: '123',
      // address: 'Bokeykhana 21/3',
      // basis: 'Basis',
      // basisDate: moment().toDate(),
      // bik: 'bik',
      // phone: '+7 (7023) 22-14-86',
      // kbe: 'kbe',
      // iik: 'iik',
      // email: 'i_set@mail.ru',
      // ceo: 'Saldayev I.N.',
      // bin: '870301351368'
    };
  }

  componentDidMount() {
    savedRun(async () => {
      await this.updateTowns();
    });
    savedRun(async () => {
      const result = await Server.Client.getBases();
      const bases = result.map((b: any, index: number) => ({
        ...b, index
      }));
      this.setState({bases, basisType: bases[0]});
    });
  }

  private async updateTowns() {
    const refTowns = await Server.Client.getTowns(this.props.i18n.lang);
    this.setState({refTowns: refTowns.entities});
  }

  private cleanEntity = {
    towns: [],
    type: ClientType.ORGANIZATION,
    name: undefined,
    password: undefined,
    confirm: undefined,
    address: undefined,
    bik: undefined,
    phone: undefined,
    kbe: undefined,
    iik: undefined,
    email: undefined,
    ceo: undefined,
    bin: undefined,
    basisType: undefined,
    basis: undefined,
    basisDate: undefined
  };

  private getMappedEntity(entity?: any) {
    const entityFull = entity || this.state;
    const _entity: any = {};
    Object.keys(this.cleanEntity).forEach(fieldname => {
      _entity[fieldname] = entityFull[fieldname];
    });
    return _entity;
  }

  private async validate(entity?: any, force = false) {
    if (!this.state.validated && !force) {
      return;
    }
    try {
      const _entity = entity || this.state;
      const schemaName = this.state.isRegistration
        ? this.state.type === ClientType.FIZ
          ? ValidationSchemaName.ClientIndividualRegistration
          : ValidationSchemaName.ClientOrganizationRegistration
        : ValidationSchemaName.ClientLogin;
      const validation = await Server.Validation.validate(schemaName, _entity);
      this.setState({validation, status: 'edit', signed: undefined});
      return validation;
    } catch (err) {
      console.error(err);
    }
  }

  private getValidationClass(fieldname: string) {
    if (!this.state.validated) {
      return '';
    }
    const error = this.state.validation?.[fieldname];
    return error ? ' is-invalid' : ' is-valid';
  }

  private async check() {
    savedRun(async () => {
      this.setState({isLoading: true});
      try {
        const validation = await this.validate(this.state, true);
        this.setState({validated: true});
        if (!validation?.success) {
          this.setState({isLoading: false});
          return;
        }
        const entity = this.getMappedEntity();
        await Server.Client.check(entity);
        this.setState({isLoading: false, validated: true, status: 'validated'});
      } catch (err) {
        this.setState({
          isLoading: false, validated: true,
          validation: {...this.state.validation, ...err.validation, success: false}
        });
        throw err;
      }
    });
  }

  private sign() {
    this.setState({isNcaLoading: true, validation: {success: true}});
    savedRun(async () => {
      try {
        const entity = this.getMappedEntity();
        const nca = new NCALayer();
        await nca.open();
        const activeTokens = await nca.getActiveTokens();
        console.log(`Active tokens from nca: ${activeTokens}`);
        const entityBase64 = Buffer.from(JSON.stringify(entity)).toString('base64');
        const signed = await nca.createCMSSignatureFromBase64('PKCS12', 'AUTHENTICATION', entityBase64);
        const validation = this.state.validation
          ? {...this.state.validation, keyUser: undefined, keyUserIndividual: undefined, keyUserOrganization: undefined}
          : undefined;
        this.setState({isNcaLoading: false, signed, status: 'signed', validation});
      } catch (err) {
        this.setState({isNcaLoading: false});
        if (err.wasClean === false) {
          window.alert(this.props.i18n.t['nca.error']);
        } else {
          if (err.error !== 'canceled') {
            this.setState({validation: {success: false, ncaError: ValidationFieldError.invalid}});
          }
          throw err;
        }
      }
    });
  }

  private async registration() {
    savedRun(async () => {
      this.setState({isLoading: true});
      try {
        await Server.Client.upsert({signed: this.state.signed});
        this.setState({
          isLoading: false, validated: true, isRegistration: false
        }, () => this.login());
      } catch (err) {
        if (err.validation) {
          this.setState({
            isLoading: false, validated: true,
            validation: {...this.state.validation, ...err.validation, success: false}
          });
          if (Object.keys(err.validation).some(key => key.startsWith('keyUser'))) {
            this.setState({status: 'validated'});
          }
        } else {
          throw err;
        }
      }
    });
  }

  private login() {
    savedRun(async () => {
      try {
        this.setState({isLoading: true});
        const validation = await this.validate(this.state, true);
        this.setState({validated: true});
        if (!validation?.success) {
          this.setState({isLoading: false});
          return;
        }
        const entity = this.getMappedEntity();
        const result = await Server.login(entity.bin, entity.password);
        if (result) {
          this.setState({isLoading: false});
          this.hide();
        } else {
          this.setState({
            validation: {
              success: false,
              password: ValidationFieldError.invalid
            }
          });
        }
        this.setState({isLoading: false});
      } catch (err) {
        this.setState({isLoading: false});
      }
    });
  }

  private hide() {
    this.setState({
      ...this.cleanEntity,
      isRegistration: false,
      validated: false,
      status: 'edit'
    });
    this.props.onHide();
  }

  render() {
    const {t, lang} = this.props.i18n;
    return (
      <Modal className='login-box'
        onKeyUp={(key: any) => key.keyCode === 13 && this.login()}
        show={this.props.show}
        size={this.state.isRegistration ? 'lg' : 'sm'}
        onHide={() => this.hide()}
      >
        <CoverSpinner show={this.state.isLoading || this.state.isNcaLoading}
          text={this.state.isNcaLoading ? t['nca.message'] : undefined}
        />
        <Modal.Header closeButton>
          <Modal.Title>{t[this.state.isRegistration ? 'login.registration' : 'nav.login']}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.isRegistration &&
            <>
              <FormGroup controlId='type'>
                <Form.Label>{t['fields.type']}</Form.Label>
                <Select isSearchable
                  options={this.state.clientTypes}
                  getOptionLabel={(option: any) => t[option.text]}
                  getOptionValue={(option: any) => option.value}
                  value={this.state.clientTypes.find((ct: any) => ct.value === this.state.type)}
                  onChange={(option) => this.setState({type: option.value}, () => this.validate())} />
              </FormGroup>
              <FormGroup controlId='towns'>
                <Form.Label>{t['fields.fromSettings']}</Form.Label>
                <Select isSearchable isMulti className={this.getValidationClass('towns')}
                  styles={{
                    control: (props, state) => ({
                      ...props,
                      borderColor: this.state.validated
                        ? this.getValidationClass('towns') === ' is-valid' ? '#179e45' : '#db2a3e'
                        : props.borderColor
                    })
                  }}
                  placeholder={t['location']}
                  isLoading={this.state.isTownsLoading}
                  options={this.state.refTowns}
                  getOptionLabel={(option: any) => [
                    option['name' + t['langUp']],
                    option.district?.['name' + t['langUp']],
                    option.district?.region?.['name' + t['langUp']]
                  ].filter(name => !!name).join(', ')}
                  getOptionValue={(option: any) => option._id}
                  value={this.state.towns}
                  onChange={(option) => this.setState({towns: option}, () => this.validate())} />
                <FormControl.Feedback type='invalid'>
                  {t['login.errors.' + this.state.validation?.towns]}
                </FormControl.Feedback>
              </FormGroup>
              {this.state.type !== ClientType.FIZ &&
                <FormGroup controlId='name'>
                  <Form.Label>{t['fields.name']}</Form.Label>
                  <FormControl type='name' placeholder={t['fields.name.placeholder']}
                    className={this.getValidationClass('name')}
                    value={this.state.name || ''}
                    onChange={(event) => this.setState({name: event.target.value}, () => this.validate())}
                  />
                  <FormControl.Feedback type='invalid'>
                    {t['login.errors.' + this.state.validation?.name]}
                  </FormControl.Feedback>
                </FormGroup>
              }
              <Row>
                <Col>
                  <FormGroup controlId='address'>
                    <Form.Label>
                      {t['fields.address' + (this.state.type === ClientType.FIZ ? '.individual' : '')]}
                    </Form.Label>
                    <FormControl type='address' placeholder={t['fields.address.placeholder']}
                      className={this.getValidationClass('address')}
                      value={this.state.address || ''}
                      onChange={(event) => this.setState({address: event.target.value}, () => this.validate())}
                    />
                    <FormControl.Feedback type='invalid'>
                      {t['login.errors.' + this.state.validation?.address]}
                    </FormControl.Feedback>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup controlId='ceo'>
                <Form.Label>{t['fields.ceo']}</Form.Label>
                <FormControl type='ceo' placeholder={t['fields.ceo.placeholder']}
                  className={this.getValidationClass('ceo')}
                  value={this.state.ceo || ''}
                  onChange={(event) => this.setState({ceo: event.target.value}, () => this.validate())}
                />
                <FormControl.Feedback type='invalid'>
                  {t['login.errors.' + this.state.validation?.ceo]}
                </FormControl.Feedback>
              </FormGroup>
              {this.state.type !== ClientType.FIZ &&
                <FormGroup>
                  <Row>
                    <Col>
                      <Form.Label>{t['fields.basisType']}</Form.Label>
                      <Select isSearchable
                        placeholder={t['fields.basisType.placeholder']}
                        options={this.state.bases}
                        getOptionLabel={(option: any) => option[lang]}
                        getOptionValue={(option: any) => option.index}
                        value={this.state.bases.find((b: any) => b.ru === this.state.basisType?.ru && b.kk === this.state.basisType?.kk) || null}
                        onChange={(option) => this.setState({basisType: {ru: option.ru, kk: option.kk}}, () => this.validate())} />
                    </Col>
                    <Col>
                      <Form.Label>{t['fields.basis']}</Form.Label>
                      <FormControl
                        className={this.getValidationClass('basis')}
                        placeholder={t['fields.basis.placeholder']}
                        value={this.state.basis || ''}
                        onChange={(event) => this.setState({basis: (event.target.value)}, () => this.validate())}
                      />
                      <FormControl.Feedback type='invalid'>{t['errors.required']}</FormControl.Feedback>
                    </Col>
                    <Col>
                      <Form.Label>{t['fields.basisDate']}</Form.Label><br />
                      <ReactDatePicker className={'form-control ' + this.getValidationClass('basisDate')}
                        dateFormat='dd.MM.yyyy'
                        selected={this.state.basisDate}
                        onChange={(date: Date) => this.setState({basisDate: date}, () => this.validate())}
                      />
                      <FormControl.Feedback type='invalid'>{t['errors.required']}</FormControl.Feedback>
                    </Col>
                  </Row>
                </FormGroup>
              }
              <Row>
                <Col>
                  <FormGroup controlId='phone'>
                    <Form.Label>{t['fields.phone']}</Form.Label>
                    <MaskedInput type='phone' placeholder={t['fields.phone.placeholder']}
                      className={'form-control' + this.getValidationClass('phone')}
                      mask='+7 (9999) 99-99-99'
                      value={this.state.phone || ''}
                      onChange={(event) => this.setState({phone: event.target.value}, () => this.validate())}
                    />
                    <FormControl.Feedback type='invalid'>
                      {t['login.errors.' + this.state.validation?.phone]}
                    </FormControl.Feedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup controlId='email'>
                    <Form.Label>{t['fields.email']}</Form.Label>
                    <FormControl type='email' placeholder={t['fields.email.placeholder']}
                      className={this.getValidationClass('email')}
                      value={this.state.email || ''}
                      onChange={(event) => this.setState({email: event.target.value}, () => this.validate())}
                    />
                    <FormControl.Feedback type='invalid'>
                      {t['login.errors.' + this.state.validation?.email]}
                    </FormControl.Feedback>
                  </FormGroup>
                </Col>
              </Row>
            </>
          }
          <Row>
            {this.state.isRegistration &&
              <Col lg={8}>
                <FormGroup controlId='iik'>
                  <Form.Label>{t['fields.iik']}</Form.Label>
                  <FormControl type='iik' placeholder={t['fields.iik.placeholder']}
                    className={this.getValidationClass('iik')}
                    value={this.state.iik || ''}
                    onChange={(event) => this.setState({iik: event.target.value.toUpperCase()}, () => this.validate())}
                  />
                  <FormControl.Feedback type='invalid'>
                    {t['login.errors.' + this.state.validation?.iik]}
                  </FormControl.Feedback>
                </FormGroup>
                <FormGroup controlId='bik'>
                  <Form.Label>{t['fields.bik']}</Form.Label>
                  <FormControl type='bik' placeholder={t['fields.bik.placeholder']}
                    className={this.getValidationClass('bik')}
                    value={this.state.bik || ''}
                    onChange={(event) => this.setState({bik: event.target.value.toUpperCase()}, () => this.validate())}
                  />
                  <FormControl.Feedback type='invalid'>
                    {t['login.errors.' + this.state.validation?.bik]}
                  </FormControl.Feedback>
                </FormGroup>
                <FormGroup controlId='kbe'>
                  <Form.Label>{t['fields.kbe']}</Form.Label>
                  <FormControl type='number' placeholder={t['fields.kbe.placeholder']}
                    className={this.getValidationClass('kbe')}
                    value={this.state.kbe || ''}
                    onChange={(event) => this.setState({kbe: event.target.value}, () => this.validate())}
                  />
                  <FormControl.Feedback type='invalid'>
                    {t['login.errors.' + this.state.validation?.kbe]}
                  </FormControl.Feedback>
                </FormGroup>
              </Col>
            }
            <Col>
              <FormGroup controlId='bin'>
                <Form.Label>{t[this.state.type !== ClientType.FIZ ? 'fields.bin' : 'fields.iin']}</Form.Label>
                <FormControl type='number' placeholder={t['fields.bin.placeholder']}
                  className={this.getValidationClass('bin')}
                  value={this.state.bin || ''}
                  onChange={(event) => this.setState({bin: event.target.value}, () => this.validate())}
                />
                <FormControl.Feedback type='invalid'>
                  {this.state.type === ClientType.ORGANIZATION
                    ? t['login.errors.bin.' + this.state.validation?.bin]
                    : t['login.errors.iin.' + this.state.validation?.bin]
                  }
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup controlId='password'>
                <Form.Label>{t['fields.password']}</Form.Label>
                <FormControl type='password' placeholder={t['fields.password.placeholder']}
                  className={this.getValidationClass('password')}
                  value={this.state.password || ''}
                  onChange={(event) => this.setState({password: event.target.value}, () => this.validate())}
                />
                <FormControl.Feedback type='invalid'>
                  {t['login.errors.password.' + this.state.validation?.password]}
                </FormControl.Feedback>
              </FormGroup>
              {this.state.isRegistration &&
                <FormGroup controlId='confirm'>
                  <Form.Label>{t['fields.confirm']}</Form.Label>
                  <FormControl type='password' placeholder={t['fields.confirm.placeholder']}
                    className={this.getValidationClass('confirm')}
                    value={this.state.confirm || ''}
                    onChange={(event) => this.setState({confirm: event.target.value}, () => this.validate())}
                  />
                  <FormControl.Feedback type='invalid'>
                    {t['login.errors.' + this.state.validation?.confirm]}
                  </FormControl.Feedback>
                </FormGroup>
              }
            </Col>
          </Row>
          <Feedback type='invalid' style={{display: 'block'}}>
            {
              this.state.validation?.keyUserOrganization === ValidationFieldError.invalid
                ? t['login.errors.sign.keyUserOrganization']
                : this.state.validation?.keyUserIndividual === ValidationFieldError.invalid
                  ? t['login.errors.sign.keyUserIndividual']
                  : this.state.validation?.ncaError === ValidationFieldError.invalid
                    ? t['nca.pki.unavailable']
                    : ''
            }
          </Feedback>
        </Modal.Body>
        <Modal.Footer>
          <div className='footer'>
            <Form.Check
              id='is-registration'
              type='switch'
              checked={this.state.isRegistration}
              label={t['login.registration']}
              onChange={(event: any) => this.setState({isRegistration: event.target.checked})}
            />
            {this.state.isRegistration
              ? this.state.status === 'edit'
                ? <Button type='button' onClick={() => this.check()}>
                  {t['actions.check']}
                </Button>
                : this.state.status === 'validated'
                  ? <Button type='button' onClick={() => this.sign()}>
                    {t['actions.authSign']}
                  </Button>
                  : this.state.status === 'signed'
                    ? <Button type='button' onClick={() => this.registration()}>
                      {t['actions.registration']}
                    </Button>
                    : null
              : <Button type='button' onClick={() => this.login()}>
                {t['actions.login']}
              </Button>
            }
          </div>
        </Modal.Footer>
      </Modal >
    );
  }
}

const Login = connect(i18nMapStateToProps)(LoginComponent);
export default Login;
