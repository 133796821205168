import React from 'react';
import {Dispatch} from 'redux';
import {BaseAllComponentProps} from '../../App';
import {connect} from 'react-redux';
import {Spinner, Container, Image, Card} from 'react-bootstrap';
import {savedRun} from '../../shared/utils';
import Server from '../../shared/server';
import {IGeoLocationState, GeoLocationActions} from '../../shared/geoLocation';
import CKEditorPage from '../../shared/components/ckeditorPage/ckeditorPage';
import {i18nMapStateToProps} from '../../i18n';

interface InternalProps extends BaseAllComponentProps {
  geoLocation: IGeoLocationState;
  updateGeolocation: (geoLocation?: IGeoLocationState) => Promise<void>;
}

interface InternalState {
  isLoading: boolean;
  entities: any[];
}

class ActualNdviComponent extends React.PureComponent<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      isLoading: true,
      entities: []
    };
  }

  componentDidMount() {
    savedRun(async () => {
      try {
        const entities = await Server.ActualNdvi.getData();
        this.setState({entities, isLoading: false});
      } catch (e) {
        this.setState({isLoading: false});
      }
    });
  }

  render() {
    const {lang} = this.props.i18n;
    const langUp = lang === 'kk' ? 'Kk' : 'Ru';
    return (
      <Container fluid='lg' className='actual-ndvi-box'>
        {this.state.isLoading &&
          <div className='spinner-box'>
            <Spinner variant='primary' animation='grow' />
          </div>
        }
        {this.state.entities.map(e =>
          <Card key={e._id} className='item-box'>
            <Card.Header>
              <Card.Title>{e['name' + langUp]}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Image src={'data:image/jpeg;base64, ' + e.buffer} width='100%' />
              <CKEditorPage content={e['content' + langUp]} />
            </Card.Body>
          </Card>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateGeolocation: GeoLocationActions.setGeoLocation(dispatch)
});

const ActualNdvi = connect(i18nMapStateToProps, mapDispatchToProps)(ActualNdviComponent);

export default ActualNdvi;
