import config from '../../config';
import store from '../../store';
import {processResponse} from '.';

class Pages {
  public static baseUrl = config.host + config.mod + config.pages;

  public static async get(name: string) {
    const lang = store.getState().i18n.lang;
    const response = await fetch(
      this.baseUrl + '/' + name + '/' + lang,
      {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      }
    );
    const result = await processResponse(response);
    return result as string;
  }

}

export default Pages;
