import React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {BaseAllComponentProps} from '../../App';
import {Container, Row, Col, Spinner, Collapse, OverlayTrigger, Popover, PopoverContent} from 'react-bootstrap';
import moment, {Moment} from 'moment';
import Select from 'react-select';
import Server from '../../shared/server';
import {savedRun} from '../../shared/utils';
import AppSelectStylesCalendar from './appSelectStylesCalendar';
import {AppState} from '../../store';
import {IGeoLocationState, GeoLocationActions} from '../../shared/geoLocation';

interface InternalProps extends BaseAllComponentProps {
  geoLocation: IGeoLocationState;
  updateGeolocation: (geoLocation?: IGeoLocationState) => Promise<void>;
}

interface InternalState {
  towns?: any[];
  selectedTowns?: any[];
  plantTypes?: any[];
  selectedPlantTypes?: any[];
  info?: any[];
  isTownsLoading: boolean;
  isPlantTypesLoading: boolean;
  isLoading: boolean;
  legendVisible: boolean;
}

class CalendarComponent extends React.PureComponent<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      isTownsLoading: true,
      isPlantTypesLoading: true,
      isLoading: true,
      legendVisible: false
    };
  }

  componentDidMount() {
    this.updateTowns();
  }

  componentDidUpdate(prevProps: InternalProps) {
    if (prevProps.i18n.lang !== this.props.i18n.lang) {
      this.updateTowns();
    }
    if (prevProps.geoLocation.success !== true && this.props.geoLocation.success === true &&
      (!this.state.selectedTowns || this.state.selectedTowns.length === 0)) {
      savedRun(async () => {
        await this.updateByPosition();
      });
    }
  }

  private updateTowns() {
    savedRun(async () => {
      try {
        const towns = await Server.Calendar.getTowns();
        this.setState({towns, isTownsLoading: false, isPlantTypesLoading: false});
      } catch (e) {
        this.setState({isTownsLoading: false, isPlantTypesLoading: false});
      }
      if (this.props.geoLocation.success === undefined) {
        savedRun(async () => await this.props.updateGeolocation());
      } else if (this.props.geoLocation.success) {
        savedRun(async () => await this.updateByPosition());
      }
    });
  }

  async updateByPosition() {
    if (this.props.geoLocation.position) {
      const info = await Server.Calendar.getInfoByPosition(this.props.geoLocation.position);
      if (info) {
        const ids: any[] = [];
        info.forEach((r: any) => r.districts.forEach((d: any) => d.towns.forEach((t: any) => ids.push(t._id))));
        this.setState({info, isLoading: false, selectedTowns: this.state.towns?.filter(t => ids.some(i => i === t._id))});
      } else {
        this.setState({isLoading: false});
      }
    }
  }

  async update(option?: any[]) {
    if (!option || option.length === 0) {
      this.setState({selectedTowns: [], info: undefined});
      return;
    }
    const ids = option?.map(t => t._id);
    this.setState({selectedTowns: option, isTownsLoading: true});
    const info = await Server.Calendar.getInfo(ids);
    this.setState({info, isLoading: false, isTownsLoading: false});
  }

  renderCalendar(sowing?: any, maturation?: any, harvesting?: any) {
    const t = this.props.i18n.t;
    const getClassNameByDate = (m: Moment): string => {
      const weekDay = m.weekday();
      let result = '';
      if (weekDay > 4) {
        result += 'weekend ';
      }
      if (sowing && sowing.expected && m.isBetween(sowing.expected.dateFrom, sowing.expected.dateTo, 'day', '[]')) {
        result += 'sowing-expected';
      }
      if (sowing && sowing.date && m.isBetween(sowing.date.dateFrom, sowing.date.dateTo, 'day', '[]')) {
        result += 'sowing-date';
      }
      if (maturation && maturation.forecastDate && m.isSame(maturation.forecastDate, 'day')) {
        result += 'maturation-forecast';
      }
      if (harvesting && harvesting.length) {
        const date = harvesting.find((h: any) => m.isBetween(h.dateFrom, h.dateTo, 'day', '[]'));
        if (date) {
          result += 'harvesting';
        }
      }
      return result;
    }

    const minDate = sowing?.expected?.dateFrom || sowing?.date?.dateFrom || maturation?.forecastDate || harvesting?.[0]?.dateFrom;
    const maxDate = harvesting?.[harvesting.length - 1]?.dateTo || maturation?.forecastDate || sowing?.date.dateTo || sowing?.expected.dateTo;
    if (!minDate) return null;
    const monthFrom = moment(minDate).startOf('month');
    const monthTo = moment(maxDate).startOf('month');
    const months = [];
    const current = monthFrom;
    while (current.isSameOrBefore(monthTo)) {
      const month = {
        label: current.format('MMMM'),
        days: [] as Array<{
          className: string;
          date: Moment;
          header?: string;
          content?: string;
        }>
      };
      const day = moment(current);
      const end = moment(current).endOf('month');
      while (day.isSameOrBefore(end)) {
        const date = moment(day);
        let className = getClassNameByDate(day);
        let {header, content} = className.indexOf('sowing-expected') > -1
          ? {header: t['calendar.sowing'], content: t['calendar.legend.sowing.expected']}
          : className.indexOf('sowing-date') > -1
            ? {header: t['calendar.sowing'], content: t['calendar.legend.sowing.date']}
            : className.indexOf('maturation') > -1
              ? {header: t['calendar.maturation'], content: t['calendar.legend.maturation.forecast']}
              : className.indexOf('harvesting') > -1
                ? {header: t['calendar.harvesting'], content: t['calendar.legend.harvesting']}
                : className.indexOf('weekend') > -1
                  ? {header: '', content: t['calendar.legend.weekend']}
                  : {header: undefined, content: undefined};
        const d = {date, className, header, content};
        month.days.push(d);
        day.add(1, 'day');
      }
      months.push(month);
      current.add(1, 'month');
    }

    return (
      <div className='tile'>
        {months.map((month) => (
          <div className='month-box' key={month.label}>
            <div className='month-label'>{month.label}</div>
            <div className='dates-box'>
              {month.days.map((d, i) =>
                d.content && d.content !== ''
                  ? <OverlayTrigger key={i} overlay={
                    <Popover id={d.date.format('dd/MM')} >
                      {/* <PopoverTitle>{d.header}</PopoverTitle> */}
                      <PopoverContent>{d.content}</PopoverContent>
                    </Popover>
                  }>
                    <div className={'day ' + d.className}
                      style={{marginLeft: (i === 0 ? (d.date.weekday() * 45.5) + 'px' : '')}}
                    >
                      {d.date.date()}
                    </div>
                  </OverlayTrigger>
                  : <div key={i} className={'day ' + d.className}
                    style={{marginLeft: (i === 0 ? (d.date.weekday() * 45.5) + 'px' : '')}}
                  >
                    {d.date.date()}
                  </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const t = this.props.i18n.t;
    const fieldAddition = this.props.i18n.lang === 'kk' ? 'Kk' : 'Ru';
    return (
      <Container fluid='xl' className='calendar-box'>
        <span className={'legend-button ' + (this.state.legendVisible ? 'active' : '')}
          onClick={() => this.setState({legendVisible: !this.state.legendVisible})}>
          {t['calendar.legend']}
        </span>
        <div className='legend-box'>
          <Collapse in={this.state.legendVisible}>
            <Row className='legend'>
              <div className='legend-tile sowing-expected'>{t['calendar.legend.sowing.expected']}</div>
              <div className='legend-tile sowing-date'>{t['calendar.legend.sowing.date']}</div>
              <div className='legend-tile maturation-forecast'>{t['calendar.legend.maturation.forecast']}</div>
              <div className='legend-tile harvesting'>{t['calendar.legend.harvesting']}</div>
              <div className='legend-tile weekend'>{t['calendar.legend.weekend']}</div>
            </Row>
          </Collapse>
        </div>
        <Row className='ml-0 mr-0'>
          <Col xl='6' md='8' sm='12' className='pl-0 pr-0'>
            <Select styles={AppSelectStylesCalendar} isSearchable={true} isMulti
              isLoading={this.state.isTownsLoading}
              placeholder={t['location']}
              options={this.state.towns}
              getOptionLabel={(option: any) => [
                option['name' + fieldAddition],
                option.district?.['name' + fieldAddition],
                option.district?.region?.['name' + fieldAddition]
              ].join(', ')}
              getOptionValue={(option: any) => option._id}
              value={this.state.selectedTowns}
              onChange={(option) => this.update(option)} />
          </Col>
        </Row>
        {this.state.isLoading &&
          <div className='calendar-spinner'>
            <Spinner animation='grow' variant='primary' />
          </div>
        }
        {this.state.info &&
          this.state.info.map((region, i) =>
            <div key={i} className='region-box'>
              <div className='region'>
                {region['name' + fieldAddition]}
              </div>
              {
                region.districts.map((district: any) =>
                  <div key={district._id} className='district-box'>
                    <div className='district'>
                      {district['name' + fieldAddition]}
                    </div>
                    {
                      district.towns.map((town: any, index: number) =>
                        <div key={index}>
                          <Row className='town-box'>
                            <Col lg={2} className='header-box'>
                              <div className='header desktop'>{t['calendar.town']}</div>
                              {/* <div className='tile'>{town['name' + fieldAddition]}</div> */}
                            </Col>
                            {town.maturation?.length > 0 &&
                              <Col lg={2} className='header-box'>
                                <div className='header'>{t['calendar.plantType']}</div>
                              </Col>
                            }
                            <Col className='header-box'>
                              <div className='header desktop'>&nbsp;</div>
                            </Col>
                          </Row>
                          {town.maturation?.length > 0
                            ? town.maturation.map((maturation: any, index: number) =>
                              <Row key={index} className='town-box'>
                                <Col lg={2} className='header-box'>
                                  <div className='tile'>{town['name' + fieldAddition]}</div>
                                </Col>
                                <Col lg={2} className='header-box'>
                                  <div className='tile'>{maturation.type['name' + fieldAddition]}</div>
                                </Col>
                                <Col className='header-box'>
                                  {this.renderCalendar(town.sowing, maturation, town.harvesting)}
                                </Col>
                              </Row>
                            )
                            : <Row className='town-box'>
                              <Col lg={2} className='header-box'>
                                <div className='tile'>{town['name' + fieldAddition]}</div>
                              </Col>
                              <Col className='header-box'>
                                {this.renderCalendar(town.sowing, null, town.harvesting)}
                              </Col>
                            </Row>
                          }
                          {/* <Row className='town-box'>
                              <Col lg={8}>
                                {town.sowing &&
                                  <Row key={index} className='p-0'>
                                    <Col lg={12} className='header-box'>
                                      <div className='header desktop'>{t['calendar.sowing']}</div>
                                    </Col>
                                    <Col lg={2} className='header-box'>
                                    </Col>
                                    <Col lg={10} className='header-box'>
                                      {this.renderCalendar(town.sowing)}
                                    </Col>
                                  </Row>
                                }
                                {town.maturation && town.maturation.length > 0 &&
                                  town.maturation.map((maturation: any, index: any) =>
                                    <Row key={index} className='p-0'>
                                      <Col lg={2} className='header-box'>
                                        <div className='header mobile'>{t['calendar.moisture']}</div>
                                        <div className='header'>{t['calendar.plantType']}</div>
                                        <div className='tile'>{maturation.type['name' + fieldAddition]}</div>
                                      </Col>
                                      <Col lg={10} className='header-box'>
                                        <div className='header desktop'>{t['calendar.moisture']}</div>
                                        {this.renderCalendar(null, maturation)}
                                      </Col>
                                    </Row>
                                  )}
                                {town.harvesting && town.harvesting.length > 0 &&
                                  <Row className='p-0'>
                                    <Col lg={12} className='header-box'>
                                      <div className='header desktop'>{t['calendar.harvesting']}</div>
                                    </Col>
                                    <Col lg={2} className='header-box' />
                                    <Col lg={10} className='header-box'>
                                      {this.renderCalendar(null, null, town.harvesting)}
                                    </Col>
                                  </Row>
                                }
                              </Col>
                            </Row>
                            <Row className='town-box'>
                              <Col lg={2} className='header-box'>
                                <div className='header desktop'>{t['calendar.town']}</div>
                                <div className='tile'>{town['name' + fieldAddition]}</div>
                              </Col>
                              <Col lg={10}>
                                {town.sowing &&
                                  <Row key={index} className='p-0'>
                                    <Col lg={12} className='header-box'>
                                      <div className='header desktop'>{t['calendar.sowing']}</div>
                                    </Col>
                                    <Col lg={2} className='header-box'>
                                    </Col>
                                    <Col lg={10} className='header-box'>
                                      {this.renderCalendar(town.sowing)}
                                    </Col>
                                  </Row>
                                }
                                {town.maturation && town.maturation.length > 0 &&
                                  town.maturation.map((maturation: any, index: any) =>
                                    <Row key={index} className='p-0'>
                                      <Col lg={2} className='header-box'>
                                        <div className='header mobile'>{t['calendar.moisture']}</div>
                                        <div className='header'>{t['calendar.plantType']}</div>
                                        <div className='tile'>{maturation.type['name' + fieldAddition]}</div>
                                      </Col>
                                      <Col lg={10} className='header-box'>
                                        <div className='header desktop'>{t['calendar.moisture']}</div>
                                        {this.renderCalendar(null, maturation)}
                                      </Col>
                                    </Row>
                                  )}
                                {town.harvesting && town.harvesting.length > 0 &&
                                  <Row className='p-0'>
                                    <Col lg={12} className='header-box'>
                                      <div className='header desktop'>{t['calendar.harvesting']}</div>
                                    </Col>
                                    <Col lg={2} className='header-box' />
                                    <Col lg={10} className='header-box'>
                                      {this.renderCalendar(null, null, town.harvesting)}
                                    </Col>
                                  </Row>
                                }
                              </Col>
                            </Row> */}
                        </div>
                      )}
                  </div>
                )
              }
            </div>
          )
        }
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  i18n: state.i18n,
  geoLocation: state.geoLocation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateGeolocation: GeoLocationActions.setGeoLocation(dispatch)
});

const Calendar = connect(mapStateToProps, mapDispatchToProps)(CalendarComponent);
export default Calendar;
