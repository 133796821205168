import React from 'react';
import {Badge, Button, Col, Container, Form, FormControl, FormGroup, FormLabel, InputGroup, Modal, ModalBody, ModalFooter, ModalTitle, Pagination, Row, Spinner, Table} from 'react-bootstrap';
import {connect} from 'react-redux';
import {BaseAllComponentProps} from '../../App';
import {i18nMapStateToProps} from '../../i18n';
import * as Icon from 'react-bootstrap-icons';
import {savedRun} from '../../shared/utils';
import Server from '../../shared/server';
import moment from 'moment';
import ModalHeader from 'react-bootstrap/ModalHeader';
import CKEditorPage from '../../shared/components/ckeditorPage/ckeditorPage';
import CoverSpinner from '../../shared/components/plain/spinner/spinner';

interface InternalProps extends BaseAllComponentProps { }
interface InternalState {
  showBalanceCharge: boolean;
  balanceChargeSum?: number;
  isBalanceChargeLoading: boolean;
  showAll: boolean;

  isLoading: boolean;
  entities: any[];
  entity?: any;

  count: number;
  page: number;
  perPage: number;
  filter: any;
  sort: any;

  pages: number[];
  pagesCount: number;

  balance?: number;
}

class BalanceChangesComponent extends React.PureComponent<InternalProps, InternalState>{
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      showBalanceCharge: false,
      isBalanceChargeLoading: false,
      isLoading: false,
      showAll: false,
      entities: [],
      count: 0,
      page: 1,
      perPage: 10,
      pages: [],
      pagesCount: 0,
      filter: {common: '', error: {$exists: false}},
      sort: {column: 'created', asc: false}
    };
  }

  private entitiesTimeout?: NodeJS.Timeout;

  private _updateEntities() {
    savedRun(async () => {
      const result = await Server.balanceChanges.get(this.state.page, this.state.perPage, this.state.filter, this.state.sort);
      if (this.entitiesTimeout) {
        clearTimeout(this.entitiesTimeout);
      }
      if (result.entities.some((e: any) => ['new', 'approved'].some(s => s === e.status))) {
        this.entitiesTimeout = setTimeout(() => {
          this._updateEntities();
        }, 10000);
      }
      const pagesCount = Math.ceil(result.count / this.state.perPage);
      const pages: number[] = [];
      for (let i = 0; i < pagesCount; i++) pages.push(i + 1);
      this.setState({count: result.count, entities: result.entities, pagesCount, pages});
    });
  }

  private _updateBalance() {
    savedRun(async () => {
      const result = await Server.Client.getBalance();
      this.setState({balance: result});
    });
  }

  componentDidMount() {
    this._updateBalance();
    this._updateEntities();
  }

  private _close(needUpdate = false) {
    this.setState({entity: undefined});
    if (needUpdate) {
      this._updateEntities();
    }
  }

  private chargeBalance(sum?: number) {
    // if (prompt('Функция в разработке') !== '220510') {
    //   return;
    // }
    if (!sum) {
      this.setState({showBalanceCharge: true});
      return;
    }
    savedRun(async () => {
      try {
        this.setState({isBalanceChargeLoading: true});
        await Server.balanceChanges.charge(sum, this.props.i18n.lang);
      } finally {
        this.setState({isBalanceChargeLoading: false});
        this.closeBalanceCharge();
      }
    });
  }

  private closeBalanceCharge(update = false) {
    this.setState({showBalanceCharge: false, isBalanceChargeLoading: false, balanceChargeSum: undefined});
    if (update) {
      this._updateBalance();
    }
  }

  render() {
    const t = this.props.i18n.t;
    return (<Container fluid='xl' className='balance-box'>
      <FormGroup>
        <Row>
          <Col>
            {t['fields.balance']} = {this.state.balance} {t['label.money.unit.KZT']}
          </Col>
          <Col className='all-check'>
            <Button variant={this.state.showAll ? 'primary' : 'light'} onClick={() => {
              const filter = {...this.state.filter};
              filter.error = this.state.showAll ? {$exists: false} : undefined;
              this.setState({showAll: !this.state.showAll, filter}, () => this._updateEntities());
            }}>
              {t['actions.show.all']}
            </Button>
          </Col>
        </Row>
      </FormGroup>
      <Row>
        <Col md='6'>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text><Icon.Search /></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder={t['fields.contract.filter']}
              value={this.state.filter['contract.number' || '']}
              onChange={(event) => this.setState({filter: {...this.state.filter, common: event.target.value}}, () => this._updateEntities())}
            />
          </InputGroup>
        </Col>
        <Col md='6'>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>{t['fields.perPage']}</InputGroup.Text>
            </InputGroup.Prepend>
            <select className='form-control'
              value={this.state.perPage}
              onChange={(event) => this.setState({perPage: +event.target.value}, () => this._updateEntities())}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </InputGroup>
        </Col>
      </Row>
      <Table striped hover>
        <thead>
          <tr>
            <th>{t['fields.balance.date']}</th>
            <th>{t['fields.balance.value']}</th>
            <th>{t['fields.balance.before']}</th>
            <th>{t['fields.balance.after']}</th>
            <th>{t['fields.contract.number']}</th>
            <th>{t['fields.balance.status']}</th>
            <th>{t['fields.actions']}</th>
          </tr>
        </thead>
        <tbody>
          {this.state.entities.map((entity, index) => {
            const showButton = ['done', 'failed'].some(s => s === entity.status);
            if (!entity.status) {
              entity.status = entity.error ? 'failed' : 'done';
            }
            return <tr key={entity._id}>
              <td>{moment(entity.created).format('YYYY.MM.DD HH:mm')}</td>
              <td>{entity.value}</td>
              <td>{entity.prevBalance}</td>
              <td>{entity.newBalance}</td>
              <td>{entity.contract?.number}</td>
              <td>
                <Badge variant={entity.status === 'failed' ? 'danger' : 'success'}>
                  {t['fields.balance.status.' + entity.status]}
                </Badge>
              </td>
              <td className='p-1'>
                <Button variant='primary' className='mr-1' disabled={!showButton}
                  onClick={() => this.setState({entity: entity})}
                >
                  {showButton
                    ? <Icon.Eye />
                    : <Spinner animation='border' size='sm' />
                  }
                </Button>
              </td>
            </tr>
          })}
        </tbody>
      </Table>
      <Pagination>
        <Pagination.First disabled={this.state.page === 1} onClick={() => this.setState({page: 1}, () => this._updateEntities())} />
        <Pagination.Prev disabled={this.state.page === 1} onClick={() => this.setState({page: this.state.page - 1}, () => this._updateEntities())} />
        {this.state.pages.map(page => <Pagination.Item key={page} active={this.state.page === page}
          onClick={() => this.setState({page}, () => this._updateEntities())}>{page}</Pagination.Item>)}
        <Pagination.Next disabled={this.state.page === this.state.pagesCount} onClick={() => this.setState({page: this.state.page + 1}, () => this._updateEntities())} />
        <Pagination.Last disabled={this.state.page === this.state.pagesCount} onClick={() => this.setState({page: this.state.pagesCount}, () => this._updateEntities())} />
      </Pagination>
      <Form.Group>
        <Button variant='primary' onClick={() => this.chargeBalance()}>
          {t['actions.balance.charge']}
        </Button>
      </Form.Group>
      <Modal show={!!this.state.entity} size='xl' onHide={() => this._close()}>
        <ModalHeader closeButton><ModalTitle>{t['actions.show']}</ModalTitle></ModalHeader>
        <ModalBody>
          <FormGroup>
            <Form.Label className='ml-1'>{t['fields.balance.value']} = {this.state.entity?.value}</Form.Label>,&nbsp;
            <Form.Label className='ml-1'>{t['fields.balance.before']} = {this.state.entity?.prevBalance}</Form.Label>,&nbsp;
            <Form.Label className='ml-1'>{t['fields.balance.after']} = {this.state.entity?.newBalance}</Form.Label>
          </FormGroup>
          {this.state.entity?.error
            ? <FormLabel>{t['label.error.balance.' + this.state.entity?.error.errorCode]}</FormLabel>
            : <CKEditorPage content={this.state.entity?.comment} />
          }
        </ModalBody>
        <ModalFooter>
          <Button variant='light' onClick={() => this._close()}>{t['actions.close']}</Button>
        </ModalFooter>
      </Modal>
      <Modal show={this.state.showBalanceCharge} size='sm'>
        <CoverSpinner show={this.state.isBalanceChargeLoading} />
        <Modal.Header closeButton onHide={() => this.closeBalanceCharge()}><Modal.Title>{t['actions.balance.charge']}</Modal.Title></Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label className='ml-1'>{t['fields.balance.value']}</Form.Label>
            <FormControl type='number' placeholder={t['fields.balance.value.placeholder']}
              value={this.state.balanceChargeSum || ''}
              onChange={(event) => this.setState({balanceChargeSum: +(event.target as HTMLInputElement).value})}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' disabled={!this.state.balanceChargeSum}
            onClick={() => this.chargeBalance(this.state.balanceChargeSum)}
          >
            {t['actions.balance.charge']}
          </Button>
          <Button variant='light' onClick={() => this.closeBalanceCharge()}>{t['actions.cancel']}</Button>
        </Modal.Footer>
      </Modal>
    </Container>);
  }
}

const BalanceChanges = connect(i18nMapStateToProps)(BalanceChangesComponent);
export default BalanceChanges;
