import React from 'react';
import {BaseAllComponentProps} from '../../App';
import {connect} from 'react-redux';
import {i18nMapStateToProps} from '../../i18n';
import {Collapse, Row, Spinner} from 'react-bootstrap';
import {YMaps, Map, Polygon, Placemark} from 'react-yandex-maps';
import yandexMapOptions from '../../shared/options/mapOptions';
import {savedRun} from '../../shared/utils';
import Server from '../../shared/server';
import YaPoint, {YaPointColor} from '../../shared/components/plain/yapoint/yapoint';
import YaPolygon, {YaPolygonColor} from '../../shared/components/plain/yapolygon/yapolygon';

interface InternalProps extends BaseAllComponentProps {

}

interface InternalState {
  droughtData: any[];
  productivityData: any[];
  legendVisible: boolean;
  isLoading: boolean;
  isMobile: boolean;
  header?: string;
}

class ForecastDroughtProductivityComponent extends React.PureComponent<InternalProps, InternalState> {

  constructor(props: InternalProps) {
    super(props);
    this.state = {
      droughtData: [],
      productivityData: [],
      legendVisible: true,
      isLoading: true,
      isMobile: window.innerWidth < 992
    };
  }

  private map: any;
  private interval: any;

  saveLegendVisible(legendVisible: boolean) {
    localStorage.setItem('droughtProductivityLegendVisibility', legendVisible.toString());
  }
  getLegendVisible() {
    const str = localStorage.getItem('droughtProductivityLegendVisibility');
    if (str) {
      return str === 'true' ? true : false;
    }
    return !this.state.isMobile;
  }

  componentDidMount() {
    const legendVisible = this.getLegendVisible();
    this.setState({legendVisible});
    savedRun(async () => {
      const [droughtData, productivityData] = await Promise.all([
        Server.Forecasts.Drought.getData(),
        Server.Forecasts.Productivity.getData()
      ]);
      this.setState({droughtData, productivityData});
      let header: string | undefined = await Server.Pages.get('droughtProductivityHeader');
      if (header === '' || header === null) header = undefined;
      this.setState({header});
    });
    window.addEventListener('resize', this.onWindowResize.bind(this));
  }
  componentDidUpdate(prevProps: InternalProps) {
    if (prevProps.i18n.lang !== this.props.i18n.lang) {
      savedRun(async () => {
        let header: string | undefined = await Server.Pages.get('droughtProductivityHeader');
        if (header === '' || header === null) header = undefined;
        this.setState({header});
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize.bind(this));
  }

  onWindowResize() {
    const isMobile = window.innerWidth < 992;
    let legendVisible = this.state.legendVisible;
    if (isMobile !== this.state.isMobile) {
      if (isMobile) {
        legendVisible = false;
      } else {
        legendVisible = this.getLegendVisible();
      }
      this.setState({isMobile, legendVisible});
    }
  }

  colorByValueDrought(value: number) {
    if (value <= -2) return YaPointColor.DroughtExtreme;
    if (value <= -1.5) return YaPointColor.DroughtStrong;
    if (value <= -1) return YaPointColor.Drought;
    if (value < 1) return YaPointColor.Norm;
    if (value < 1.5) return YaPointColor.Water;
    if (value < 2) return YaPointColor.WaterStrong;
    if (value >= 2) return YaPointColor.WaterExtreme;
    return YaPointColor.Norm;
  }

  colorByValuesProductivity(color: string) {
    if (color === 'Low') return YaPolygonColor.Low;
    if (color === 'Middle') return YaPolygonColor.Middle;
    if (color === 'High') return YaPolygonColor.High;
  }

  render() {
    const {t, lang} = this.props.i18n;
    return (
      <div className='drought-box'>
        <div className='legend-box'>
          <div className={'legend-button ' + (this.state.legendVisible ? 'active' : '')}
            onClick={() => {
              const legendVisible = !this.state.legendVisible;
              if (!this.state.isMobile) {
                this.saveLegendVisible(legendVisible);
              }
              this.setState({legendVisible});
            }}>
            {t['forecast.drought.legend']}
          </div>
          <Collapse dimension={this.state.isMobile ? 'height' : 'width'}
            in={this.state.legendVisible}
            onEnter={() => this.interval = setInterval(() => this.map?.container.fitToViewport(), 1)}
            onEntered={() => {
              clearInterval(this.interval);
              this.map?.container.fitToViewport();
            }}
            onExit={() => this.interval = setInterval(() => this.map?.container.fitToViewport(), 1)}
            onExited={() => {
              clearInterval(this.interval);
              this.map?.container.fitToViewport();
            }}
          >
            <div>
              <div className='collapse-content'>
                <div className='legend-header'>{t['forecast.drought.legend.drought']}</div>
                <Row className='legend'>
                  <div className='legend-tile'>
                    <YaPoint color={YaPointColor.DroughtExtreme} />
                    <div className='text'>{t['forecast.drought.legend.high']}</div>
                  </div>
                  <div className='legend-tile'>
                    <YaPoint color={YaPointColor.DroughtStrong} />
                    <div className='text'>{t['forecast.drought.legend.middle']}</div>
                  </div>
                  <div className='legend-tile'>
                    <YaPoint color={YaPointColor.Drought} />
                    <div className='text'>{t['forecast.drought.legend.low']}</div>
                  </div>
                  <div className='legend-tile'>
                    <YaPoint color={YaPointColor.Norm} />
                    <div className='text'>{t['forecast.drought.legend.norm']}</div>
                  </div>
                  <div className='legend-tile'>
                    <YaPoint color={YaPointColor.Water} />
                    <div className='text'>{t['forecast.droughtWater.legend.low']}</div>
                  </div>
                  <div className='legend-tile'>
                    <YaPoint color={YaPointColor.WaterStrong} />
                    <div className='text'>{t['forecast.droughtWater.legend.middle']}</div>
                  </div>
                  <div className='legend-tile'>
                    <YaPoint color={YaPointColor.WaterExtreme} />
                    <div className='text'>{t['forecast.droughtWater.legend.high']}</div>
                  </div>
                </Row>
                <div className='legend-header'>{t['forecast.productivity.legend']}</div>
                <Row className='legend'>
                  <div className='legend-tile'>
                    <YaPolygon color={YaPolygonColor.Low} />
                    <div className='text'>{t['forecast.productivity.legend.low']}</div>
                  </div>
                  <div className='legend-tile'>
                    <YaPolygon color={YaPolygonColor.Middle} />
                    <div className='text'>{t['forecast.productivity.legend.middle']}</div>
                  </div>
                  <div className='legend-tile'>
                    <YaPolygon color={YaPolygonColor.High} />
                    <div className='text'>{t['forecast.productivity.legend.high']}</div>
                  </div>
                </Row>
              </div>
            </div>
          </Collapse>
        </div>
        {this.state.isLoading &&
          <div className='drought-spinner'>
            <Spinner animation='grow' variant='primary' />
          </div>
        }
        {this.state.header &&
          <div className='header'>{this.state.header}</div>
        }
        <div className='map-box'>
          <YMaps>
            <Map instanceRef={(ref: any) => this.map = ref}
              onLoad={() => this.setState({isLoading: false})} width='100%' height='100%'
              {...yandexMapOptions}
              modules={['geoObject.addon.balloon']}
            >
              {this.state.productivityData.map((item, key) => {
                if (item.geometry.type === 'MultiPolygon') {
                  const coords = item.geometry.coordinates;
                  item.geometry = {
                    type: 'Polygon',
                    coordinates: coords[0]
                  };
                }
                return <Polygon
                  key={key}
                  properties={{
                    balloonContentHeader: lang === 'kk' ? item.nameKk : item.nameRu,
                    balloonContentBody: item.valueFrom + ' - ' + item.valueTo + ' ' + t['unit.c/ha']
                  }}
                  defaultGeometry={item.geometry}
                  defaultOptions={{
                    fillColor: this.colorByValuesProductivity(item.color)
                  }}
                />
              })}
              {this.state.droughtData.map((item, key) =>
                <Placemark key={key}
                  properties={{
                    balloonContentHeader: lang === 'kk' ? item.town.nameKk : item.town.nameRu,
                    balloonContentBody: 'SPI ' + item.value
                  }}
                  defaultOptions={{
                    preset: 'islands#circleDotIcon',
                    iconColor: this.colorByValueDrought(item.value),
                    iconShadow: true
                  }}
                  defaultGeometry={[item.town.geometry.coordinates[1], item.town.geometry.coordinates[0]]}
                />
              )}
            </Map>
          </YMaps>
        </div>
      </div>
    );
  }
}

const ForecastDroughtProductivity = connect(i18nMapStateToProps)(ForecastDroughtProductivityComponent);

export default ForecastDroughtProductivity;
