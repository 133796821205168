import React from 'react';
import Server from '../server';
import {getDotNotated, ValidationSchemaName} from '../server/validation';

interface InternalState {
  validated: boolean;
  validation?: any;
}

class BaseValidation<TProps, TState> extends React.PureComponent<TProps, InternalState & TState> {

  protected schema?: ValidationSchemaName;

  protected async validate(entity = this.state, force = false) {
    if (!this.schema || (!this.state.validated && !force)) {
      return;
    }
    const validation = await Server.Validation.validate(this.schema, entity ?? this.state);
    this.setState({validation});
    return validation;
  }

  getValidationClass(fieldName: string) {
    if (!this.state.validated || !this.state.validation) {
      return '';
    }
    const value = getDotNotated(this.state.validation, fieldName);
    return !value ? ' is-valid' : ' is-invalid';
  }

  getValidationColor(fieldName: string, defaultColor?: string) {
    if (!this.state.validated || !this.state.validation) {
      return defaultColor;
    }
    const value = getDotNotated(this.state.validation, fieldName);
    return !value ? '#2eb85c' : '#e55353';
  }

}

export default BaseValidation;
