import React from 'react';
import {Button, FormControl} from 'react-bootstrap';
import {connect} from 'react-redux';
import {BaseI18nComponentProps} from '../../App';
import {i18nMapStateToProps} from '../../i18n';
import BaseValidation from '../../shared/components/baseValidation';
import CustomSelect from '../../shared/components/plain/select/customselect';
import {ValidationSchemaName} from '../../shared/server/validation';
import * as Icon from 'react-bootstrap-icons';
import {savedRun} from '../../shared/utils';

interface InternalProps extends BaseI18nComponentProps {
  townsRef: any[];
  defaultValue: any;
  onChanged: (service: any) => void;

  validated: boolean;
  onValidationChanged: (validation: any) => void;
  onRemove: () => void;
}

interface InternalState {
  _id: string;
  nameRu: string;
  nameKk: string;
  price: number;
  isBase: boolean;
  needTown?: boolean;
  needCount?: boolean;
  count: number;
  towns: any[];
}

class ServiceComponent extends BaseValidation<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);
    this.schema = ValidationSchemaName.ClientContractService;
    this.state = {
      validated: props.validated,
      ...props.defaultValue
    };
  }

  componentDidMount() {
    if (this.props.validated) {
      savedRun(async () => {await this.validate();});
    }
  }

  componentDidUpdate(prevProps: InternalProps) {
    if (prevProps.validated !== this.props.validated) {
      this.setState({validated: this.props.validated}, () => {
        if (this.props.validated) {
          this.validate();
        }
      });
    }
  }

  private project(entity = this.state) {
    return {
      _id: entity._id,
      nameRu: entity.nameRu,
      nameKk: entity.nameKk,
      price: entity.price,
      isBase: entity.isBase,
      needTown: entity.needTown,
      needCount: entity.needCount,
      count: entity.count,
      towns: entity.towns
    };
  }

  protected async validate(entity = this.state) {
    const validation = await super.validate(entity);
    this.props.onValidationChanged(validation);
    return validation;
  }

  render() {
    const {t, lang} = this.props.i18n;
    const s = this.state;
    return <tr className='service-box'>
      <td>{lang === 'kk' ? s.nameKk : s.nameRu}</td>
      <td className='cell-custom'>{s.needTown &&
        <CustomSelect isSearchable isMulti
          className={this.getValidationClass('towns')}
          placeholder={t['fields.towns.placeholder']}
          options={this.props.townsRef}
          getOptionLabel={(option: any) => [
            option['name' + t['langUp']],
            option.district?.region?.['name' + t['langUp']]
          ].filter(name => !!name).join(', ')}
          getOptionValue={(option: any) => option._id}
          value={s.towns}
          onChange={(options) => {
            this.setState({towns: options || [], count: options?.length || 0}, () => {
              this.props.onChanged(this.project());
              this.validate();
            });
          }} />
      }</td>
      <td className='cell-custom cell-center'>{s.price}</td>
      <td className='cell-custom cell-center'>{s.needCount
        ? <FormControl type='number'
          placeholder={t['fields.count.placeholder']}
          value={s.count}
          onChange={(event) => {
            let count = +event.target.value;
            if (count < 1) {
              count = 1;
            }
            if (count > 10) {
              count = 10;
            }
            this.setState({count}, () => {
              this.props.onChanged(this.project());
              this.validate();
            });
          }}
        />
        : this.state.count
      }</td>
      <td className='cell-custom cell-center'>{s.price * s.count}</td>
      <td className='cell-custom'><Button variant='danger'
        onClick={() => this.props.onRemove()}
      ><Icon.Trash /></Button></td>
    </tr>;
  }
}

const Service = connect(i18nMapStateToProps)(ServiceComponent);
export default Service;
