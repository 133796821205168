import React from 'react';
import NavBar from './routes/navbar/navbar';
import {Switch, Route, RouteComponentProps, Redirect, HashRouter} from 'react-router-dom';
import {connect, Provider} from 'react-redux';
import store, {AppState, IUser} from './store';
import {i18nMapStateToProps, I18nState} from './i18n';
import MainPage from './routes/mainPage/mainPage';
import AboutUsPage from './routes/mainPage/aboutUs';
import WeatherPage from './routes/weather/weatherPage';
import Footer from './shared/components/footer/footer';
import Calendar from './routes/calendar/calendar';
import ForecastMoisture from './routes/forecasts/moisture';
import ForecastDroughtProductivity from './routes/forecasts/droughtProductuvity';
import ActualMoisture from './routes/actualMoisture/actualMoisture';
import ActualNdvi from './routes/actualNdvi/actualNdvi';
import Contracts from './routes/contracts/contracts';
import ClientText from './routes/client/text';
import Ndvi from './routes/client/ndvi';
import BalanceChanges from './routes/balance/balanceChanges';
import Instruction from './routes/mainPage/instruction';

export enum Routes {
  Home = '/home',
  Weather = '/weather',
  Forecasts = '/forecasts',
  Calendar = '/calendar',
  WaterForecast = '/water',
  DroughtProductivityForecast = '/drought-productivity',
  ActualData = '/actual',
  ActualWater = '/water',
  ActualNDVI = '/ndvi',
  AboutUs = '/aboutus',
  Contracts = '/contracts',
  BalanceChanges = '/balance/changes',
  ClientText = '/client/text',
  ClientNdvi = '/client/ndvi',
  Instruction = '/instruction'
}

interface IRoute {
  path: Routes;
  text: string;
  authOnly?: boolean;
  exact?: boolean;
  component?: any;
  childs?: IRoute[];
}

export const routes: IRoute[] = [
  {path: Routes.Home, text: 'nav_main', component: MainPage},
  {path: Routes.Weather, text: 'nav_weather', component: WeatherPage},
  {
    path: Routes.Forecasts, text: 'nav_forecasts',
    childs: [
      {path: Routes.Calendar, text: 'nav_forecast_calendar', component: Calendar},
      {path: Routes.WaterForecast, text: 'nav_forecast_water', component: ForecastMoisture},
      {
        path: Routes.DroughtProductivityForecast,
        text: 'nav_forecast_drought_productivity', component: ForecastDroughtProductivity
      },
      {path: Routes.ClientText, text: 'nav.client.text', component: ClientText, authOnly: true}
    ]
  },
  {
    path: Routes.ActualData, text: 'nav_actual',
    childs: [
      {path: Routes.ActualWater, text: 'nav_actual_water', component: ActualMoisture},
      {path: Routes.ActualNDVI, text: 'nav_actual_ndvi', component: ActualNdvi},
      {path: Routes.ClientNdvi, text: 'nav.client.ndvi', component: Ndvi, authOnly: true}
    ]
  },
  {path: Routes.AboutUs, text: 'nav_about_us', component: AboutUsPage},
];

export const ClientRoutes = [
  Routes.Contracts
];

export interface BaseI18nComponentProps {
  i18n: I18nState;
}

export interface BaseAllComponentProps extends BaseI18nComponentProps, RouteComponentProps { }

interface InternalProps extends BaseI18nComponentProps {
  user?: IUser;
  updated: boolean;
}

class AppRouteComponent extends React.PureComponent<InternalProps> {
  render() {
    return (
      <>
        <div className='app-bg'></div>
        <div className='route-box'>
          <HashRouter>
            <Route component={NavBar} />
            <Switch>
              <Route path={Routes.Instruction} exact component={Instruction} />
              {routes.filter(r => !!this.props.user || r.authOnly !== true).map((route, i) => (
                route.childs
                  ? route.childs.filter(r => !!this.props.user || r.authOnly !== true).map((routeChild, i) => (
                    <Route key={i} path={route.path + routeChild.path} exact={route.exact} component={routeChild.component} />
                  ))
                  : <Route key={i} path={route.path} exact={route.exact} component={route.component} />
              ))}
              {this.props.user &&
                <Route path={Routes.Contracts} component={Contracts} />
              }
              {this.props.user &&
                <Route path={Routes.BalanceChanges} component={BalanceChanges} />
              }
              {this.props.updated &&
                <Redirect path='/' to={Routes.Home} />
              }
              {/* <Route path='/' component={ErrorPage} /> */}
            </Switch>
          </HashRouter>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToprops = (state: AppState) => ({
  ...i18nMapStateToProps(state),
  user: state.identity.user,
  updated: state.identity.updated
});
const AppRoute = connect(mapStateToprops)(AppRouteComponent);

class App extends React.PureComponent {
  render() {
    return (
      <Provider store={store}>
        <AppRoute />
      </Provider>
    );
  }
}

export default App;
