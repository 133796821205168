import {addQueryParams, defaultHeaders, processResponse} from '.';
import config from '../../config';


export enum ValidationSchemaName {
  ClientLogin = 'ClientLogin',
  ClientIndividualRegistration = 'ClientIndividualRegistration',
  ClientOrganizationRegistration = 'ClientOrganizationRegistration',
  ChangePassword = 'ClientChangePassword',
  ClientContract = 'ClientContract',
  ClientContractService = 'ClientContractService'
}

export enum ValidationFieldError {
  invalid = 'invalid',
  required = 'required',
  duplicate = 'duplicate',
  notE = 'notE',
  notNotE = 'notNotE',
  notGt = 'notGt',
  notGte = 'notGte',
  notLt = 'notLt',
  notLte = 'notLte',
  notContains = 'notContains',
  notFitRegexp = 'notFitRegexp'
}

interface IValidationField {
  required?: boolean;
  fitRegex?: string;
  notFitRegex?: RegExp;
  schema?: IValidationSchema | IValidationSchema[];
}

type TComparingType = '<' | '>' | '=' | '!=' | '<=' | '>=' | 'contains';
type TFields = {[fieldName: string]: IValidationField};
export interface IValidationSchema {
  fields: TFields;
  compare?: Array<{
    compareType: TComparingType;
    firstField: string;
    secondField: string;
    dotNotation?: boolean;
  }>;
  conditional?: Array<{
    field: string;
    value: any;
    fields: TFields;
  }>;
}

export const getDotNotated = (entity: any, path: string) => {
  const pathArr = path.split('.');
  let value = entity;
  pathArr.forEach(path => {
    if (value) {
      value = value[path];
    }
  });
  return value;
};

export const validate = (schema: IValidationSchema, entity: any) => {
  if (!entity) {
    return {success: false};
  }
  const errors: {[fieldName: string]: any} = {};
  const _schema = {...schema};
  if (_schema.conditional) {
    _schema.conditional.forEach(c => {
      if (entity[c.field] === c.value) {
        _schema.fields = {..._schema.fields, ...c.fields}
      }
    });
  }
  Object.keys(_schema.fields).forEach(fieldname => {
    const field = entity[fieldname];
    const fieldSchema = _schema.fields[fieldname];
    if (!fieldSchema) {
      return;
    }
    if (fieldSchema.required && (field === null || field === undefined || (field.length === 0))) {
      errors[fieldname] = ValidationFieldError.required;
      return;
    }
    if (fieldSchema.fitRegex && !new RegExp(fieldSchema.fitRegex).test(field)) {
      errors[fieldname] = ValidationFieldError.notFitRegexp;
      return;
    }
    if (fieldSchema.schema) {
      if (Array.isArray(fieldSchema.schema)) {
        const result = field?.map((entity: any) => {
          const result = validate((fieldSchema.schema as any[])[0] as IValidationSchema, entity);
          return result.success ? undefined : result;
        });
        if (result?.some((e: any) => e)) {
          errors[fieldname] = result;
        }
      } else {
        const value = field;
        if (value) {
          const result = validate(fieldSchema.schema, value);
          if (result.success !== true) {
            errors[fieldname] = result;
          }
        }
      }
    }
  });
  if (_schema.compare) {
    _schema.compare.forEach(schema => {
      const type = schema.compareType;
      const firstValue = schema.dotNotation
        ? getDotNotated(entity, schema.firstField)
        : entity[schema.firstField];
      const secondValue = schema.dotNotation
        ? getDotNotated(entity, schema.secondField)
        : entity[schema.secondField];
      if (type === '<' && !(firstValue < secondValue)) {
        errors[schema.firstField] = ValidationFieldError.notLt;
      }
      if (type === '<=' && !(firstValue <= secondValue)) {
        errors[schema.firstField] = ValidationFieldError.notLte;
      } if (type === '=' && !(firstValue === secondValue)) {
        errors[schema.firstField] = ValidationFieldError.notE;
      } if (type === '!=' && !(firstValue !== secondValue)) {
        errors[schema.firstField] = ValidationFieldError.notNotE;
      } if (type === '>' && !(firstValue > secondValue)) {
        errors[schema.firstField] = ValidationFieldError.notGt;
      } if (type === '>=' && !(firstValue >= secondValue)) {
        errors[schema.firstField] = ValidationFieldError.notGte;
      } if (type === 'contains' && !(firstValue.indexOf(secondValue) > -1)) {
        errors[schema.firstField] = ValidationFieldError.notContains;
      }
    });
  }
  return Object.keys(errors).length > 0 ? {...errors, success: false} : {success: true};
};

export class ValidationError extends Error {
  public validation: any;
  constructor(message: string, validation: any) {
    super(message);
    this.name = 'ValidationError';
    this.validation = validation;
    Error.captureStackTrace?.(this, ValidationError);
  }
}

class Validation {
  private static schemas: {[name: string]: any} = {};

  private static async getSchema(schemaName: ValidationSchemaName): Promise<IValidationSchema> {
    let schema = this.schemas[schemaName];
    if (!schema) {
      const response = await fetch(
        config.host + config.mod + '/validation/schemas' + addQueryParams({schemaName}),
        {
          method: 'GET',
          headers: defaultHeaders
        });
      const result = await processResponse(response);
      if (result) {
        schema = result;
        this.schemas[schemaName] = schema;
      }
    }
    return schema;
  }

  public static async validate(schemaName: ValidationSchemaName, entity: any) {
    const schema = await this.getSchema(schemaName);
    if (!schema) {
      return {success: false};
    }
    return validate(schema, entity);
  }

}

export default Validation;
