import {addQueryParams, headersWithAuth, processResponse} from '.';
import config from '../../config';
import {Lang} from '../../i18n';

class BalanceChanges {
  private static baseUrl = config.host + config.mod + '/client/balance/changes';

  public static async get(page: number, perPage: number, filter?: string, sort?: any) {
    const response = await fetch(this.baseUrl + addQueryParams({
      page, perPage, filter: filter ? JSON.stringify(filter) : '{}', sort: sort ? JSON.stringify(sort) : '{}'
    }), {
      method: 'GET',
      headers: await headersWithAuth()
    });
    return await processResponse(response);
  }

  public static async charge(sum: number, lang: Lang) {
    const response = await fetch(this.baseUrl + '/authsumrequest' + addQueryParams({sum, lang}), {
      method: 'GET',
      headers: await headersWithAuth()
    });
    const body = await processResponse(response);
    console.log('Body', body);
    const form = document.createElement('form');
    form.name = 'SendOrder';
    form.method = 'post';
    form.action = config.kkbUrl;
    Object.keys(body).forEach(key => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = body[key];
      form.appendChild(input);
    });
    form.hidden = true;
    document.body.appendChild(form);
    form.submit();
  }

}

export default BalanceChanges;
