import React from 'react';
import {Button, Form, FormControl, FormGroup, Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {BaseI18nComponentProps} from '../../App';
import {AppState, IUser} from '../../store';
import Server from '../../shared/server';
import {ValidationSchemaName} from '../../shared/server/validation';
import {savedRun} from '../../shared/utils';
import CoverSpinner from '../../shared/components/plain/spinner/spinner';

interface InternalProps extends BaseI18nComponentProps {
  show: boolean;
  onHide: () => void;
  user?: IUser;
}

interface InternalState {
  validated: boolean;
  validation?: any;
  isLoading: boolean;

  password?: string;
  confirm?: string;
}

class ChangePasswordComponent extends React.PureComponent<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      validated: false,
      isLoading: false
    };
  }

  private async validate(entity?: any, force = false) {
    if (!this.state.validated && !force) {
      return;
    }
    try {
      const _entity = entity || this.state;
      const schemaName = ValidationSchemaName.ChangePassword;
      const validation = await Server.Validation.validate(schemaName, _entity);
      this.setState({validation});
      return validation;
    } catch (err) {
      console.error(err);
    }
  }

  private getValidationClass(fieldname: string) {
    if (!this.state.validated) {
      return '';
    }
    const error = this.state.validation?.[fieldname];
    return error ? ' is-invalid' : ' is-valid';
  }

  private hide() {
    this.setState({validated: false, password: undefined, confirm: undefined});
    this.props.onHide();
  }

  private change() {
    savedRun(async () => {
      try {
        if (!this.props.user) {
          return;
        }
        this.setState({isLoading: true});
        const validation = await this.validate(this.state, true);
        this.setState({validated: true});
        if (!validation?.success) {
          this.setState({isLoading: false});
          return;
        }
        this.setState({isLoading: true});
        const result = await Server.changePassword(this.props.user.id, this.state.password || '', this.state.confirm || '');
        if (result) {
          this.setState({password: undefined, confirm: undefined});
        }
        this.setState({isLoading: false});
        this.hide();
      } catch (err) {
        this.setState({isLoading: false});
      }
    });
  }

  render() {
    const t = this.props.i18n.t;
    return (
      <Modal size='sm' show={this.props.show} onHide={() => this.hide()} >
        <CoverSpinner show={this.state.isLoading} />
        <Modal.Header closeButton>
          <Modal.Title>{t['actions.changePassword']}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup controlId='password'>
            <Form.Label>{t['fields.password']}</Form.Label>
            <FormControl type='password' placeholder={t['fields.password.placeholder']}
              className={this.getValidationClass('password')}
              value={this.state.password || ''}
              onChange={(event) => this.setState({password: event.target.value}, () => this.validate())}
            />
            <FormControl.Feedback type='invalid'>
              {t['login.errors.' + this.state.validation?.password]}
            </FormControl.Feedback>
          </FormGroup>
          <FormGroup controlId='confirm'>
            <Form.Label>{t['fields.confirm']}</Form.Label>
            <FormControl type='password' placeholder={t['fields.confirm.placeholder']}
              className={this.getValidationClass('confirm')}
              value={this.state.confirm || ''}
              onChange={(event) => this.setState({confirm: event.target.value}, () => this.validate())}
            />
            <FormControl.Feedback type='invalid'>
              {t['login.errors.' + this.state.validation?.confirm]}
            </FormControl.Feedback>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button type='button' onClick={() => this.change()}>
            {t['actions.change']}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  i18n: state.i18n,
  user: state.identity.user
});

const ChangePassword = connect(mapStateToProps)(ChangePasswordComponent);
export default ChangePassword;
