import translationsRu from './lang/ru.json';
import translationsKk from './lang/kk.json';
import {Dispatch} from 'redux';
import {IAction, AppState} from './store';
import moment from 'moment';
// react-datepicker
import ru from 'date-fns/locale/ru';
import kk from 'date-fns/locale/kk';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
registerLocale('ru', ru);
registerLocale('kk', kk);
setDefaultLocale('ru');

export type Lang = 'ru' | 'kk';

export interface I18nState {
  lang: Lang;
  t: {[key: string]: string};
}

const defaultState: I18nState = {lang: 'ru', t: {}};

export enum I18nActionType {
  setLang = 'setLang'
}

export class Actions {
  public static setLang = (dispatch: Dispatch<IAction>) => (lang: Lang) => {
    moment.locale(lang);
    const t = lang === 'kk'
      ? translationsKk
      : translationsRu;
    dispatch({type: I18nActionType.setLang, payload: {lang, t}});
    localStorage.setItem('lang', lang);
    setDefaultLocale(lang);
  }
}

export const i18nReducer = (state: I18nState = defaultState, action: IAction): I18nState => {
  switch (action.type) {
    case I18nActionType.setLang: {
      return {...state, ...action.payload};
    }
    default: return state;
  }
}

export const i18nMapStateToProps = (state: AppState) => ({
  i18n: state.i18n
});
