import React from 'react';
import {Col, Container, Row, Image} from 'react-bootstrap';
import {BaseAllComponentProps} from '../../App';
import {connect} from 'react-redux';
import {i18nMapStateToProps} from '../../i18n';
import stationSrc from '../../assets/img/photos/station.jpeg';
import meterSrc from '../../assets/img/photos/meter.jpg';
import station2Src from '../../assets/img/photos/station2.jpeg';
import CKEditorPage from '../../shared/components/ckeditorPage/ckeditorPage';
import kgmLogoSrc from '../../assets/img/kgm.png';

interface InternalProps extends BaseAllComponentProps { }

class MainPageComponent extends React.PureComponent<InternalProps> {
  private pageName = '';

  constructor(props: InternalProps) {
    super(props);
    this.pageName = 'home';
  }

  render() {
    const t = this.props.i18n.t;
    return (
      <div className='main-box'>
        <Container fluid='lg' className='page'>
          <div className="head">
            <div className='head-title'>
              <a href='https://kazhydromet.kz' target='__blank'><Image src={kgmLogoSrc} className='kgm-logo' /></a>
              <div className="title">{t['mainpage.header.title']}</div>
            </div>
            <div className="subtitle">{t['mainpage.header.subtitle']}</div>
          </div>
          <div className='content-start'>
            <Row>
              <Col lg='8'>
                <div className='header'>{t['mainpage.content.start.header']}</div>
                <div className='block'>{t['mainpage.content11']}</div>
                <div className='block'>{t['mainpage.content12']}</div>
                <div className='block'>{t['mainpage.content13']}</div>
                <div className='block'>{t['mainpage.content14']}</div>
              </Col>
              <Col className='col-right'>
                <div className='image-box1'>
                  <Image src={stationSrc} className='image' alt='station' />
                  <div className='image-title'>{t['mainpage.imagetitle1']}</div>
                </div>
                <div className='image-box1'>
                  <Image src={meterSrc} className='image' alt='meter' />
                  <div className='image-title'>{t['mainpage.imagetitle2']}</div>
                </div>
              </Col>
            </Row>
          </div>
          <div className='content'>
            <CKEditorPage pageName={this.pageName} />
          </div>
          <div className='content-end'>
            <div className='block'>{t["mainpage.content.end.header"]}</div>
            <Row>
              <Col lg='8'>
                <div className='block1'>{t['mainpage.content21']}</div>
                <div className='block2'>{t['mainpage.content22']}</div>
                <div className='block3'>{t['mainpage.content23']}</div>
              </Col>
              <Col className='col2'>
                <Image src={station2Src} className='image' alt='station2' />
              </Col>
            </Row>
          </div>
        </Container>
      </div >
    );
  }
}

const MainPage = connect(i18nMapStateToProps)(MainPageComponent);

export default MainPage;
