import React from 'react';
import {Container} from 'react-bootstrap';
import {BaseAllComponentProps} from '../../App';
import {connect} from 'react-redux';
import {i18nMapStateToProps} from '../../i18n';
import CKEditorPage from '../../shared/components/ckeditorPage/ckeditorPage';

interface InternalProps extends BaseAllComponentProps {}

class AboutUsPageComponent extends React.PureComponent<InternalProps> {
  private pageName = '';

  constructor(props: InternalProps) {
    super(props);
    this.pageName = 'aboutus';
  }

  render() {
    return (
      <div className='aboutus-scroll-box'>
        <Container fluid='lg' className='about-us-box'>
          <CKEditorPage pageName={this.pageName} />
        </Container>
      </div >
    );
  }
}

const AboutUsPage = connect(i18nMapStateToProps)(AboutUsPageComponent);

export default AboutUsPage;
