import React from 'react';
import {Button, FormGroup, FormLabel, Modal, Spinner, Table} from 'react-bootstrap';
import {connect} from 'react-redux';
import {AppState, IUser} from '../../store';
import {savedRun} from '../../shared/utils';
import Server from '../../shared/server';
import {BaseI18nComponentProps} from '../../App';
import Select from 'react-select';
import BaseValidation from '../../shared/components/baseValidation';
import Service from './service';
import {ValidationFieldError, ValidationSchemaName} from '../../shared/server/validation';

interface InternalProps extends BaseI18nComponentProps {
  user?: IUser;

  show: boolean;
  onHide: (needUpdate: boolean) => void;
  onEnter: () => void;
  contract: any;
}
interface InternalState {
  isSaving: boolean;
  isTownsLoading: boolean;
  isServicesLoading: boolean;

  refTowns: any[];
  servicesRef: any[];

  _id?: string;
  number?: string;
  clientName?: string;
  clientCeo?: string;
  signDate?: Date;
  state: string;
  clientId?: string;
  services: any[];
}

class ContractComponent extends BaseValidation<InternalProps, InternalState> {
  private servicesValidation: any[] = [];
  private servicesRef: any[] = [];

  constructor(props: InternalProps) {
    super(props);
    this.schema = ValidationSchemaName.ClientContract;
    this.state = {
      validated: false,

      isSaving: false,
      isTownsLoading: false,
      isServicesLoading: false,

      refTowns: [],
      servicesRef: [],

      ...this.newEntity
    };
  }

  private async getClonedServices() {
    if (this.servicesRef.length === 0) {
      await savedRun(async () => {
        const result = await Server.Contract.getServices();
        this.servicesRef = result.entities.map((e: any) => {
          e.count = 1;
          return e;
        });
      });
    }
    return this.servicesRef.map(s => {
      const result: any = {};
      Object.keys(s).forEach((key: string) => {
        result[key] = s[key];
      });
      return result;
    });
  }

  private sortServices(services: any[]) {
    const langUp = this.props.i18n.t['langUp'];
    services.sort((s1: any, s2: any) => s1['name' + langUp] > s2['name' + langUp] ? 1 : -1);
    return services;
  }

  private newEntity: any = {
    _id: undefined,
    number: undefined,
    clientName: undefined,
    clientCeo: undefined,
    signDate: undefined,
    clientId: this.props.user?.id,
    services: []
  };

  private project(entity: any = this.state) {
    const result: any = {};
    Object.keys(this.newEntity).forEach(key => {
      result[key] = entity[key];
    });
    return result;
  }

  private async updateTowns() {
    if (this.state.refTowns.length !== 0 || this.state.isTownsLoading) return;
    this.setState({isTownsLoading: true});
    try {
      const refTowns = await Server.Client.getTowns(this.props.i18n.lang);
      this.setState({refTowns: refTowns.entities, isTownsLoading: false});
    } catch (err) {
      this.setState({isTownsLoading: false, refTowns: []});
      throw err;
    }
  }

  componentDidMount() {
    savedRun(async () => await Promise.all([
      this.updateTowns(),
      Server.Validation.validate(ValidationSchemaName.ClientContractService, {}) // preupdate the validation schema
    ]));
  }

  componentDidUpdate(prevProps: InternalProps) {
    if (prevProps.i18n.lang !== this.props.i18n.lang) {
      this.sortServices(this.state.servicesRef);
    }
    if (!prevProps.user && this.props.user) {
      this.setState({clientId: this.props.user.id});
    }
  }

  protected async validate(entity = this.state, force = false) {
    const validation: any = await super.validate(entity, force);
    if (!validation) return validation;
    const servicesValidation = this.servicesValidation.some(sv => sv && !sv.success);
    if (servicesValidation) {
      validation.services = ValidationFieldError.invalid;
      if (validation.success) validation.success = false;
    }
    return validation;
  }

  private onEnter() {
    this.props.onEnter();
    savedRun(async () => {
      try {
        this.setState({isServicesLoading: true});
        let servicesServer = await this.getClonedServices();
        let state: Partial<InternalState> = {
          ...this.newEntity, ...this.props.contract,
          isServicesLoading: false,
          servicesRef: []
        };
        if (this.props.contract._id) {
          state.servicesRef = servicesServer
            .filter(service => !this.props.contract.services.some((s: any) => s._id === service._id));
        } else {
          state.servicesRef = servicesServer.filter(s => !s.isBase);
          state.services = servicesServer.filter(s => s.isBase);
        }
        this.sortServices(state.servicesRef || []);
        this.setState(state as any);
      } catch (err) {
        this.setState({isServicesLoading: false});
        throw err;
      }
    });
  }

  private onHide(needUpdate = false) {
    this.setState({
      servicesRef: [],
      validated: false,
      validation: undefined,
      ...this.newEntity
    });
    this.props.onHide(needUpdate);
  }

  private save() {
    savedRun(async () => {
      this.setState({validated: true, isSaving: true});
      try {
        const validation = await this.validate(this.state, true);
        if (!validation?.success) {
          this.setState({validation, isSaving: false});
          return;
        }
        const result = await Server.Contract.upsert(this.project());
        this.setState({isSaving: false});
        if (result) {
          this.onHide(true);
        }
      } catch (err) {
        if (err.validation) {
          this.setState({
            isSaving: false, validated: true,
            validation: {...this.state.validation, ...err.validation, success: false}
          });
        } else {
          throw err;
        }
      } finally {
        this.setState({isSaving: false});
      }
    });
  }

  render() {
    const t = this.props.i18n.t;
    return <Modal size='xl' className='contract-box'
      show={this.props.show}
      onHide={() => this.onHide()}
      onEnter={() => this.onEnter()}
    >
      {this.state.isSaving &&
        <div className='contract-disabled' />
      }
      <Modal.Header closeButton>
        <Modal.Title>{t['actions.contract.add']}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormLabel>{t['label.service.list']}</FormLabel>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t['fields.name']}</th>
              <th className='town-header'>{t['fields.town']}</th>
              <th>{t['fields.price']}</th>
              <th>{t['fields.count']}</th>
              <th>{t['fields.totalPrice']}</th>
              <th>{t['fields.actions']}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.services.map((service, index) =>
              <Service key={service._id}
                validated={this.state.validated}
                onValidationChanged={(validation) => this.servicesValidation[index] = validation}
                townsRef={this.state.refTowns}
                defaultValue={service}
                onChanged={(service) => {
                  const services = this.state.services;
                  services[index] = service;
                }}
                onRemove={() => {
                  const s = this.state.services.splice(index, 1)[0];
                  this.state.servicesRef.push(s);
                  this.setState({services: [...this.state.services], servicesRef: [...this.state.servicesRef]});
                  this.servicesValidation.splice(index, 1);
                }}
              />
            )}
          </tbody>
        </Table>
        <FormGroup>
          <Select isSearchable isLoading={this.state.isServicesLoading}
            placeholder={t['fields.service.placeholder']}
            options={this.state.servicesRef}
            getOptionLabel={(option: any) => option['name' + t['langUp']]}
            getOptionValue={(option: any) => option._id}
            value={null}
            onChange={(option) => {
              this.setState({
                servicesRef: this.state.servicesRef.filter(o => o !== option),
                services: [...this.state.services, option]
              }, () => this.validate());
            }} />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        {this.state.services.length > 0 &&
          <Button variant='primary' onClick={() => this.save()}>
            {this.state.isSaving &&
              <Spinner animation='border' size='sm' className='m-1' />
            }
            {t['actions.save']}
          </Button>
        }
        <Button variant='light' onClick={() => this.onHide()}>
          {t['actions.cancel']}
        </Button>
      </Modal.Footer>
    </Modal>;
  }
}

const mapStateToProps = (state: AppState) => ({
  i18n: state.i18n,
  user: state.identity.user
});

const Contract = connect(mapStateToProps)(ContractComponent);
export default Contract;
