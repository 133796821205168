import config from '../config';

type KeyType = 'AUTHENTICATION' | 'SIGNATURE';
type StorageType = 'PKCS12';

class NCALayer {
  private ws?: WebSocket;
  private resolve?: (result?: any) => void;
  private reject?: (error?: any) => void;

  private promisify = (func: (...args: any[]) => void): (...args: any[]) => Promise<any> =>
    (...args) => new Promise((resolve, reject) => {
      if (this.resolve) {
        reject(new Error('Another operation runs already'));
      }
      this.resolve = resolve;
      this.reject = reject;
      try {
        func(...args);
      } catch (err) {
        reject(err);
      }
    });

  private resolvePromise(result: any, resolved = true) {
    if (resolved) {
      this.resolve?.(result);
    } else {
      this.reject?.(result);
    }
    this.resolve = undefined;
    this.reject = undefined;
  }

  private messageHandler(message: MessageEvent) {
    const result = JSON.parse(message.data);
    if (result.result) {
      return;
    } else if (result.code === '200') {
      this.resolvePromise(result.responseObject);
    } else if (result.code === '500' && result.message === 'action.canceled') {
      this.resolvePromise({error: 'canceled'}, false);
    } else {
      this.resolvePromise({error: result}, false);
    }
  }

  public async open() {
    return new Promise<void>((resolve, reject) => {
      this.ws = new WebSocket(config.ncaLayerUrl);
      this.ws.onerror = (err) => {
        console.error(err);
      };
      this.ws.onclose = (event) => {
        if (event.wasClean) {
          console.log('connection has been closed');
        } else {
          console.error('Connection error');
          reject(event);
        }
        console.log('Code: ' + event.code + ' Reason: ' + event.reason);
      }
      this.ws.onmessage = (event) => this.messageHandler(event);
      this.ws.onopen = () => resolve();
    });
  }

  private _getActiveTokens = this.promisify(() => {
    var getActiveTokens = {
      "module": "kz.gov.pki.knca.commonUtils",
      "method": "getActiveTokens"
    };
    this.ws?.send(JSON.stringify(getActiveTokens));
  });
  public async getActiveTokens() {return await this._getActiveTokens()}

  private _createCMSSignatureFromBase64 = this.promisify((storage, keyType, base64ToSign, addData) => {
    var createCMSSignatureFromBase64 = {
      "module": "kz.gov.pki.knca.commonUtils",
      "method": "createCMSSignatureFromBase64",
      "args": [storage, keyType, base64ToSign, addData]
    };
    this.ws?.send(JSON.stringify(createCMSSignatureFromBase64));
  });
  public async createCMSSignatureFromBase64(storage: StorageType, keyType: KeyType, base64ToSign: string, addData = true) {
    return await this._createCMSSignatureFromBase64(storage, keyType, base64ToSign, addData);
  }

  // private _showFileChooser = this.promisify((fileExtension, currentDirectory: string) => {
  //   var showFileChooser = {
  //     "module": "kz.gov.pki.knca.commonUtils",
  //     "method": "showFileChooser",
  //     "args": [fileExtension, currentDirectory]
  //   };
  //   console.log(fileExtension, currentDirectory);
  //   this.ws?.send(JSON.stringify(showFileChooser));
  // });
  // public async showFileChooser(fileExtension: string, currentDirectory: 'showFileChooserBack') {
  //   return await this._showFileChooser(fileExtension, currentDirectory);
  // }

  // private _createCMSSignatureFromFile = this.promisify((keyType, filePath, addData) => {
  //   var createCMSSignatureFromFile = {
  //     "module": "kz.gov.pki.knca.commonUtils",
  //     "method": "createCMSSignatureFromFile",
  //     "args": [this.storages[0], keyType, filePath, addData]
  //   };
  //   this.ws?.send(JSON.stringify(createCMSSignatureFromFile));
  // });

  // public async createCMSSignatureFromFile(keyType: 'SIGNATURE', filePath: string, addData = true) {
  //   return await this._createCMSSignatureFromFile(keyType, filePath, addData);
  // }

}

export default NCALayer;
