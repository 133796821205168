import {StylesConfig} from 'react-select';

const font = {
  fontFamily: 'Myriad Pro',
  fontSize: 14,
  color: '#ffffff'
};

const selectedOption = {
  borderRadius: '5px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#53acdb',
  background: 'linear-gradient(180deg, #3c88c3, #0762a3)'
};

const hoveredOption = {
  background: '#3b9ad9'
};

const AppSelectStyles: StylesConfig = {
  container: (provided, state) => ({
    ...provided, ...font,
    borderRadius: '5px',
    background: 'linear-gradient(180deg, #3c88c3, #0762a3)',
    color: '#ffffff',
  }),
  control: (provided, state) => ({
    ...provided,
    background: '#00000000'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    background: '#00000000'
  }),
  input: (provided, state) => ({
    ...provided, ...font
  }),
  placeholder: (provided, state) => ({
    ...provided, ...font,
    color: '#ffffff6c'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#ffffff'
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#3b80b8dd'
  }),
  singleValue: (provided, state) => ({
    ...provided, ...font
  }),
  groupHeading: (provided, state) => ({
    color: '#ffffff',
    fontSize: '1.4em',
    padding: '.5em',
    textAlign: 'center'
  }),
  option: (provided, state) => {
    const p = {
      ...provided,
      ':active': hoveredOption
    };
    if (state.isSelected) {
      return {...p, ...selectedOption};
    }
    if (state.isFocused) {
      return {...p, ...hoveredOption};
    }
    return p;
  }
}

export default AppSelectStyles;
