import {Dispatch} from 'redux';
import {IAction} from '../store';

export interface IGeoLocationState {
  position?: Position;
  success?: boolean;
}

const defaultState: IGeoLocationState = {};

export enum GeoLocationActionType {
  setLocation = 'setLocation'
}

const getLocation = async (): Promise<Position> => {
  const task = new Promise<Position>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position: Position) => resolve({
        timestamp: position.timestamp,
        coords: position.coords,
      }),
      (e: any) => reject(e),
      {enableHighAccuracy: false});
  });
  return await task;
};

export class GeoLocationActions {
  public static setGeoLocation = (dispatch: Dispatch<IAction>) => async (position?: IGeoLocationState) => {
    try {
      const pos = position || await getLocation();
      dispatch({type: GeoLocationActionType.setLocation, payload: {position: pos, success: true}});
    } catch (e) {
      if (e.code === 1) {
        dispatch({type: GeoLocationActionType.setLocation, payload: {success: false}});
      }
    }
  }
}

export const geoLocationReducer = (state: IGeoLocationState = defaultState, action: IAction): IGeoLocationState => {
  switch (action.type) {
    case GeoLocationActionType.setLocation: {
      return {...state, ...action.payload};
    }
    default: return state;
  }
}
