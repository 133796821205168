import config from '../../config';
import store from '../../store';
import {processResponse, addQueryParams} from '.';

class Calendar {
  public static baseUrl = config.host + config.mod + config.calendar;

  public static async getTowns() {
    const t = store.getState().i18n.t;
    const response = await fetch(
      this.baseUrl + '/towns' + addQueryParams({sort: JSON.stringify({column: 'name' + t['langUp'], asc: true})}),
      {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      }
    );
    const result = await processResponse(response);
    return result.entities as any[];
  }

  public static async getInfo(towns?: string[]) {
    const lang = store.getState().i18n.lang;
    const response = await fetch(
      this.baseUrl + '/info' + (towns ? addQueryParams({lang, towns: towns.join('|')}) : ''),
      {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      });
    const result = await processResponse(response);
    return result as any[];
  }

  public static async getInfoByPosition(position: Position) {
    const response = await fetch(
      this.baseUrl + '/info/position' + addQueryParams({
        position: JSON.stringify({
          type: 'Point',
          coordinates: [position.coords.longitude, position.coords.latitude]
        })
      }),
      {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      });
    const result = await processResponse(response);
    return result as any[];
  }

  // public static async getPoints() {
  //   const response = await fetch(this.baseUrl + this.pointsUrl, {
  //     method: 'GET',
  //     headers: {'Content-Type': 'application/json'}
  //   });
  //   return await response.json();
  // }
}

export default Calendar;
