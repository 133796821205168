import {addQueryParams, headersAuthOnly, headersWithAuth, processResponse} from '.';
import config from '../../config';

export type TClientContractStatus = 'draft' | 'generate' | 'new' | 'check' | 'active' | 'returned' | 'done';

class Contract {
  private static baseUrl = config.host + config.mod + '/client/contracts';

  public static async getServices() {
    const response = await fetch(this.baseUrl + '/services', {
      method: 'GET',
      headers: await headersWithAuth()
    });
    return await processResponse(response);
  }

  public static async upsert(entity: any) {
    const response = await fetch(this.baseUrl, {
      method: 'POST',
      headers: await headersWithAuth(),
      body: JSON.stringify(entity)
    });
    return await processResponse(response);
  }

  public static async get(page: number, perPage: number, filter?: string) {
    const response = await fetch(this.baseUrl + addQueryParams({
      page, perPage, filter: filter ? JSON.stringify({number: {$regex: filter, $options: 'i'}}) : '{}'
    }), {
      method: 'GET',
      headers: await headersWithAuth()
    });
    return await processResponse(response);
  }

  public static async remove(id: string) {
    const response = await fetch(this.baseUrl + '/' + id, {
      method: 'DELETE',
      headers: await headersWithAuth()
    });
    return await processResponse(response);
  }

  public static async sendToCheck(signed: Blob, filename: string, contractId: TClientContractStatus) {
    const form = new FormData();
    form.append('file', signed, filename);
    const response = await fetch(this.baseUrl + '/status/check/' + contractId, {
      method: 'PUT',
      headers: await headersAuthOnly(),
      body: form
    });
    return await processResponse(response);
  }

  public static async getFileToken(contractId: string, fileId: string) {
    const response = await fetch(
      this.baseUrl + '/' + contractId + '/file/' + fileId + '/token', {
      method: 'GET',
      headers: await headersWithAuth()
    });
    return await processResponse(response);
  }

}

export default Contract;
