import React, {ChangeEvent} from 'react';
import InputMask from 'react-input-mask';

interface InternalProps {
  type: 'phone' | 'email';
  className: string;
  placeholder: string;
  mask: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

class MaskedInput extends React.PureComponent<InternalProps> {
  render() {
    return (
      <InputMask
        {...this.props}
      // onKeyUp={(event) => {
      //   console.log('pressed');
      //   const input = event.target as HTMLInputElement;
      //   console.log(event.keyCode);
      //   console.log(input.selectionStart);
      //   console.log(input.value);
      //   if (event.keyCode === 8) {

      //   } else if (event.keyCode === 46) {

      //   }
      // }}
      />
    );
  }
}

export default MaskedInput
