import React from 'react';
import {BaseAllComponentProps} from '../../App';
import {connect} from 'react-redux';
import {i18nMapStateToProps} from '../../i18n';
import {Collapse, Row, Spinner} from 'react-bootstrap';
import {YMaps, Map, Placemark} from 'react-yandex-maps';
import yandexMapOptions from '../../shared/options/mapOptions';
import {savedRun} from '../../shared/utils';
import Server from '../../shared/server';
import YaPoint, {YaPointColor} from '../../shared/components/plain/yapoint/yapoint';

interface InternalProps extends BaseAllComponentProps {

}

interface InternalState {
  moisturePoints: any[];
  isLoading: boolean;
  legendVisible: boolean;
  isMobile: boolean;
  header?: string;
}

class ForecastMoistureComponent extends React.PureComponent<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      moisturePoints: [],
      isLoading: true,
      legendVisible: false,
      isMobile: window.innerWidth < 992
    };
  }

  private map?: any;

  saveLegendVisible(legendVisible: boolean) {
    localStorage.setItem('moistureLegendVisibility', legendVisible.toString());
  }
  getLegendVisible() {
    const str = localStorage.getItem('moistureLegendVisibility');
    if (str) {
      return str === 'true' ? true : false;
    }
    return !this.state.isMobile;
  }

  componentDidMount() {
    const legendVisible = this.getLegendVisible();
    this.setState({legendVisible});
    window.addEventListener('resize', this.onWindowResize.bind(this));
    savedRun(async () => {
      const moisturePoints = await Server.Forecasts.Moisture.getData();
      this.setState({moisturePoints});
      let header: string | undefined = await Server.Pages.get('waterHeader');
      if (header === '' || header === null) header = undefined;
      this.setState({header});
    });
  }
  componentDidUpdate(prevProps: InternalProps) {
    if (prevProps.i18n.lang !== this.props.i18n.lang) {
      savedRun(async () => {
        let header: string | undefined = await Server.Pages.get('waterHeader');
        if (header === '' || header === null) header = undefined;
        this.setState({header});
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize.bind(this));
  }

  onWindowResize() {
    const isMobile = window.innerWidth < 992;
    let legendVisible = this.state.legendVisible;
    if (isMobile !== this.state.isMobile) {
      if (isMobile) {
        legendVisible = false;
      } else {
        legendVisible = this.getLegendVisible();
      }
      this.setState({isMobile, legendVisible});
    }
  }

  colorByEstimation(estimation: string) {
    switch (estimation) {
      case 'notEnough': return YaPointColor.Low;
      case 'satisfactorily': return YaPointColor.Middle;
      case 'optimally': return YaPointColor.High;
    }
  }

  interval: any;

  render() {
    const {t, lang} = this.props.i18n;
    return (
      <div className='moisture-box'>
        <div className='legend-box'>
          <div className={'legend-button ' + (this.state.legendVisible ? 'active' : '')}
            onClick={() => {
              const legendVisible = !this.state.legendVisible;
              if (!this.state.isMobile) {
                this.saveLegendVisible(legendVisible);
              }
              this.setState({legendVisible});
            }}>
            {t['forecast.water.legend']}
          </div>
          <Collapse dimension={this.state.isMobile ? 'height' : 'width'}
            in={this.state.legendVisible}
            onEnter={() => this.interval = setInterval(() => this.map?.container.fitToViewport(), 1)}
            onEntered={() => {
              clearInterval(this.interval);
              this.map?.container.fitToViewport();
            }}
            onExit={() => this.interval = setInterval(() => this.map?.container.fitToViewport(), 1)}
            onExited={() => {
              clearInterval(this.interval);
              this.map?.container.fitToViewport();
            }}
          >
            <div>
              <div className='collapse-content'>
                <div className='legend-header'>{t['forecast.water.legend.header']}</div>
                <Row className='legend'>
                  <div className='legend-tile'>
                    <YaPoint color={YaPointColor.Low} />
                    <div className='text'>{t['forecast.water.legend.low']}</div>
                  </div>
                  <div className='legend-tile'>
                    <YaPoint color={YaPointColor.Middle} />
                    <div className='text'>{t['forecast.water.legend.middle']}</div>
                  </div>
                  <div className='legend-tile'>
                    <YaPoint color={YaPointColor.High} />
                    <div className='text'>{t['forecast.water.legend.high']}</div>
                  </div>
                </Row>
              </div>
            </div>
          </Collapse>
        </div>
        {this.state.isLoading &&
          <div className='moisture-spinner'>
            <Spinner animation='grow' variant='primary' />
          </div>
        }
        {this.state.header &&
          <div className='header'>{this.state.header}</div>
        }
        <div className='map-box'>
          <YMaps>
            <Map instanceRef={(ref: any) => this.map = ref}
              onLoad={() => this.setState({isLoading: false})} height='100%' width='100%'
              {...yandexMapOptions}
              modules={['geoObject.addon.balloon']}
            >
              {this.state.moisturePoints.map((station, key) =>
                <Placemark key={key}
                  properties={{
                    balloonContentHeader: lang === 'kk' ? station.town.nameKk : station.town.nameRu,
                    balloonContentBody: station.types[0].expected + t['unit.mm.short'] + ' ' + station.types[0].expectedPercent + '% ' + t['forecast.water.fromNPV']
                  }}
                  defaultOptions={{
                    preset: 'islands#circleDotIcon',
                    iconColor: this.colorByEstimation(station.types[0].estimation)
                  }}
                  defaultGeometry={[station.town.geometry?.coordinates[1], station.town.geometry?.coordinates[0]]}
                />
              )}
            </Map>
          </YMaps>
        </div>
      </div>
    );
  }
}

const ForecastMoisture = connect(i18nMapStateToProps)(ForecastMoistureComponent);

export default ForecastMoisture;
