import config from '../../config';
import {processResponse, addQueryParams} from '.';
import {Lang} from '../../i18n';

export enum WeatherIcon {
  Clear = 'Clear', // Ясно
  Cloudy = 'Cloudy', // Облачно
  ClearCloudy = 'ClearCloudy', // Переменная облачность
  ClearCloudyPrecipitation = 'ClearCloudyPrecipitation', // Переменная облачность, осадки
  ClearCloudyRain = 'ClearCloudyRain', // Переменная облачность, дождь
  ClearCloudySnow = 'ClearCloudySnow', // Переменная облачность, снег
  ClearCloudyStorm = 'ClearCloudyStorm', // Переменная облачность, гроза
  CloudyRain = 'CloudyRain', // Облачно, дождь
  CloudyRainStorm = 'CloudyRainStorm', // Облачно, дождь, гроза
  CloudySnow = 'CloudySnow', // Облачно, снег
  ClearNight = 'ClearNight', // Ясно, ночь
  ClearCloudyNight = 'ClearCloudyNight', // Переменная облачность, ночь
  ClearCloudyRainNight = 'ClearCloudyRainNight' // Переменная облачность, дождь, ночь
}

class Weather {
  public static baseUrl = config.host + config.mod + config.weather;
  public static townsUrl = '/actual/towns';
  public static positionUrl = '/position';

  public static async getTowns() {
    const response = await fetch(this.baseUrl + this.townsUrl, {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    });
    return await processResponse(response);
  }

  public static async getTown(id: string, lang: Lang) {
    const response = await fetch(this.baseUrl + this.townsUrl + '/' + id + addQueryParams({lang}), {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    });
    return await processResponse(response);
  }

  public static async getTownByPosition(position: any, lang: Lang) {
    const response = await fetch(
      this.baseUrl + this.townsUrl + this.positionUrl + addQueryParams({
        lang,
        point: JSON.stringify({
          type: "Point",
          coordinates: [position.coords.longitude, position.coords.latitude]
        })
      }),
      {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      });
    return await processResponse(response);
  }
}

export default Weather;
