import {addQueryParams, headersWithAuth, processResponse} from '.';
import config from '../../config';

class Ndvi {
  public static baseUrl = config.host + config.mod + '/client';

  public static async updateDigest(digest: string) {
    const response = await fetch(this.baseUrl + '/gis/temp/update', {
      method: 'PUT',
      headers: await headersWithAuth(),
      body: JSON.stringify({digest})
    });
    return await processResponse(response);
  }

  public static async getInfo() {
    const response = await fetch(this.baseUrl + '/ndvi/info', {
      method: 'GET',
      headers: await headersWithAuth()
    });
    return await processResponse(response);
  }

  public static async get(contractId: string, date: string) {
    const response = await fetch(this.baseUrl + '/ndvi' + addQueryParams({contractId, date}), {
      method: 'GET',
      headers: await headersWithAuth()
    });
    return await processResponse(response);
  }

}

export default Ndvi;
