let config = {
  ncaLayerUrl: 'wss://127.0.0.1:13579/',
  // kkbUrl: 'https://testpay.kkb.kz/jsp/process/logon.jsp',
  kkbUrl: 'https://epay.kkb.kz/jsp/process/logon.jsp',
  host: window.location.origin,
  mod: '/api',
  identity: '/identity',
  pages: '/pages',
  weather: '/weather',
  calendar: '/calendar',
  actualMoisture: '/actual/moisture',
  actualNdvi: '/actual/ndvi',
  forecastMoisture: '/forecasts/moisture',
  forecastDrought: '/forecasts/drought',
  forecastProductivity: '/forecasts/productivity'
};

// if (env === 'development') {
//   config = {
//     ...config,
//     host: 'http://localhost:3001',
//     mod: ''
//   };
// } else if (env === 'production') {
//   config = {
//     ...config,
//     host: window.location.origin
//   };
// }

export default config;
