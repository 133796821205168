import config from '../../config';
import {processResponse, defaultHeaders, addQueryParams, headersWithAuth} from '.';
import {Lang} from '../../i18n';

export enum ClientType {
  ORGANIZATION = 'ORGANIZATION',
  INDIVIDUAL = 'INDIVIDUAL',
  FIZ = 'FIZ'
}

class Client {
  public static baseUrl = config.host + config.mod + '/client';

  public static async getBases() {
    const response = await fetch(this.baseUrl + '/bases');
    return await processResponse(response);
  }

  public static async getTowns(lang: Lang) {
    const sort = lang === 'kk'
      ? {nameKk: 1}
      : {nameRu: 1};
    const response = await fetch(this.baseUrl + '/towns' + addQueryParams({
      sort: JSON.stringify(sort)
    }), {
      method: 'GET',
      headers: defaultHeaders
    });
    const result = await processResponse(response);
    return result;
  }

  public static async check(entity: any) {
    const response = await fetch(this.baseUrl + '/check', {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify(entity)
    });
    const result = await processResponse(response);
    return result;
  }

  public static async upsert(entity: any) {
    const response = await fetch(this.baseUrl + '/registration', {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify(entity)
    });
    const result = await processResponse(response);
    return result;
  }

  public static async getMeteograms() {
    const response = await fetch(this.baseUrl + '/meteograms', {
      method: 'GET',
      headers: await headersWithAuth()
    });
    return await processResponse(response);
  }

  public static async getTexts() {
    const response = await fetch(this.baseUrl + '/texts', {
      method: 'GET',
      headers: await headersWithAuth()
    });
    return await processResponse(response);
  }

  public static async getText(contractId: string, date: string) {
    const response = await fetch(this.baseUrl + '/texts/one' + addQueryParams({contractId, date}), {
      method: 'GET',
      headers: await headersWithAuth()
    });
    return await processResponse(response);
  }

  public static async getBalance() {
    const response = await fetch(this.baseUrl + '/balance', {
      method: 'GET',
      headers: await headersWithAuth()
    });
    return await processResponse(response);
  }

}

export default Client;
