import React from 'react';

export enum YaPointColor {
  Low = '#eec085',
  Middle = '#7cd544',
  High = '#2a6de2',
  DroughtExtreme = '#a5722d',
  DroughtStrong = '#e5a475',
  Drought = '#f5d5b8',
  Norm = '#00dd00',
  Water = '#b8d5f5',
  WaterStrong = '#75a4e5',
  WaterExtreme = '#2d72d5'
}

interface InternalProps {
  color: YaPointColor;
}

class YaPoint extends React.PureComponent<InternalProps> {
  render() {
    return (
      <div className='point-box'>
        <div className='point-radius' style={{borderColor: this.props.color}}>
          <div className='point' style={{backgroundColor: this.props.color}} />
        </div>
      </div>
    );
  }
}

export default YaPoint;
